import DescriptionIcon from '@material-ui/icons/Description';
import ArticleCategoryList from './ArticleCategoryList'
import ArticleCategoryEdit from './ArticleCategoryEdit'
import ArticleCategoryCreate from './ArticleCategoryCreate'

export default {
  create: ArticleCategoryCreate,
  edit: ArticleCategoryEdit,
  list: ArticleCategoryList,
  icon: DescriptionIcon,
};