import Quill from 'quill';

const Clipboard = Quill.import('modules/clipboard');

export class MyClipboard extends Clipboard {
  constructor(...args) {
    super(...args);
  }

  static shouldPaste({ target }) {
    // if the paste event target match the embed blot input class name
    // just return false to stop the event
    console.log("target.className ", target.name )
    if (target.name === 'figcaption-input') {
      return false;
    }
    // otherwise return true
    return true;
  }

  onPaste(e) {
    console.log("Parst11e",e);
    // when paste event happened
    // first call shouldPaste to check if this event should continue
    if (MyClipboard.shouldPaste(e)) {
      return super.onPaste(e);
    }
    e.preventDefault();
    return false;
  }
}
