import * as React from 'react';
import {FC} from 'react';
import {
  Create,
  EditProps,
} from 'react-admin';
import {HotelForm} from 'src/resources/property/hotel/HotelForm'
import {PropertyType} from 'src/types'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новая Гостиница</span>

const HotelCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <HotelForm redirect={'show'} initialValues={{type: PropertyType.Hotel}}/>
    </Create>
  );
};

export default HotelCreate;