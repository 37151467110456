import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {AdminRoles} from 'src/types'


export const AdministratorForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>

      <SelectInput
        source="role"
        label={'Роль'}
        choices={AdminRoles}
        fullWidth
        variant={'outlined'}
        validate={requiredValidate}
      />
      <TextInput
        source="email"
        label={'Email'}
        fullWidth
        variant={'outlined'}
        validate={requiredValidate}
      />
      <TextInput
        source="name"
        label={'Имя'}
        fullWidth
        variant={'outlined'}
      />

      <TextInput
        source="setPassword"
        label={'Пароль'}
        variant={'outlined'}
        fullWidth
      />

    </SimpleForm>
  );
};

const requiredValidate = [required()];