import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SimpleForm, TextField, ReferenceField, ReferenceInput, required, AutocompleteInput, FormTab
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import AssetField from 'src/components/fields/AssetField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'name': 'Название',
      'createdAt': 'Создано',
    },
    data:  posts.map(item => ({
      ...item,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Tag'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <ReferenceInput  label="Категория" source="tagCategoryId" reference="tag-category"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}

                     fullWidth={true} perPage={100} resettable={true} alwaysOn={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <TextInput source="name||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
  </Filter>
)

const TagList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Теги"
      empty={<EmptyList title={'Нет категорий тегов'} description={'Вы можете добавить категорию тегов'} buttonText={'Добавить категорию тегов'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <TextField source={'name'} label={'Название'}/>
      <TextField source={'placeholderName'} label={'Где выводится'}/>
      <TextField source={'slug'} label={'URI'}/>
      <ReferenceField label="Категория" source="tagCategoryId" reference="tag-category">
        <TextField source="name"/>
      </ReferenceField>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />

    </Datagrid>
    </List>
  );
}

export default TagList;
