import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useChoices } from 'ra-core';

const useStyles = makeStyles(
  {
    checkbox: {
      height: 32,
    },
  },
  { name: 'DayTypeInputItem' }
);

const DayTypeInputItem = props => {
  const {
    classes: classesOverride,
    id,
    choice,
    onChange,
    name,
    translateChoice,
    value,
    ...rest
  } = props;
  const classes = useStyles(props);


  return (
    <FormControlLabel
      htmlFor={`${name}`}
      key={name}
      onChange={onChange}
      label={''}
      control={
        <Select
          id={`${name}`}
          name={name}
          color="primary"
          variant={'outlined'}
          className={classes.checkbox}
          value={`${id}||${value}`}
        >
          <MenuItem value={`${id}||working`}>Рабочий</MenuItem>
          <MenuItem value={`${id}||dayOff`}>Выходной</MenuItem>
        </Select>
      }
    />
  );
};

export default DayTypeInputItem;
