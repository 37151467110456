import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {BooleanField} from 'react-admin'



const VisibilityField = (props) => {
return (<BooleanField
  TrueIcon={VisibilityIcon}
  FalseIcon={VisibilityOffIcon}
  {...props}/>)
}

export default VisibilityField;