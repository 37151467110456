import PersonIcon from '@material-ui/icons/Person';
import ProfileList from 'src/resources/profile/ProfileList'
import ProfileShow from './ProfileShow'
import ProfileCreate from 'src/resources/profile/ProfileCreate'
import ProfileEdit from 'src/resources/profile/ProfileEdit'

export default {
  create: ProfileCreate,
  edit: ProfileEdit,
  show: ProfileShow,
  list: ProfileList,
  icon: PersonIcon,
};