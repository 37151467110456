import * as React from 'react';
import {FC, useState} from 'react';
import {
  Edit,
  EditButton,
  EditProps,
} from 'react-admin';
import {UserForm} from 'src/resources/user/UserForm'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Пользователь {record.name}</span> : null;

const UserEdit: FC<EditProps> = props => {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<Title/>}>
     <UserForm isEdit/>
    </Edit>
  );
};
export default UserEdit;