import * as React from 'react';
import {FC, useState} from 'react';
import {
  Create,
  EditProps,
} from 'react-admin';
import {ArticleForm} from 'src/resources/article/ArticleForm'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новая Статья</span>

const ArticleCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <ArticleForm/>
    </Create>
  );
};

export default ArticleCreate;