import * as React from 'react';
import { FC } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import {PublicFieldProps, InjectedFieldProps} from 'react-admin';
import {getMediaPath} from 'src/utils/media'

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      margin: '0px',
      padding: '0px',
      listStyleType: 'none',
    },
    image: {
      maxHeight: '4rem',
      maxWidth: '4rem',
    },
  },
  { name: 'RaImageField' }
);

export interface AssetFieldProps extends PublicFieldProps, InjectedFieldProps {
  src?: string;
  title?: string;
  classes?: object;
}

const AssetField: FC<AssetFieldProps> = props => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    src,
    title,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const recordValue = get(record, source);
  const sourceValue = recordValue ? (Array.isArray(get(record, source)) ? recordValue.map(i => i.source) : [getMediaPath(recordValue?.source)]) : null;
  const classes = useStyles(props);
  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}

      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className}/>
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul
        className={classnames(classes.list, className)}
      >
        {sourceValue.map((file, index) => {
          const fileTitleValue = get(file, title) || title;
          const srcValue = get(file, src) || title;

          return (
            <li key={index}>
              <img
                alt={fileTitleValue}
                title={fileTitleValue}
                src={`${sourceValue}?preset=tiny`}
                className={classes.image}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = get(record, title) || title;

  return (
    <div className={className} >
      <img
        title={titleValue}
        alt={titleValue}
        src={`${sourceValue}?preset=tiny`}
        className={classes.image}
      />
    </div>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
AssetField.displayName = 'ImageField';

AssetField.defaultProps = {
  addLabel: true,
};

export default AssetField;