import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {useForm, useFormState} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import {MediaInput} from 'src/components/inputs/MediaInput'
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import TagCreateModal from 'src/resources/tag/TagCreateModal'
import {
  ArticleEntityListType,
  ArticleEntityListTypeList,
  ArticleType,
  BannerType,
  BannerTypeList, BannerViewType, BannerViewTypeList
} from 'src/types'
import {formatListValue} from 'src/utils/exporter'


const InfoTab = props => {
  const form = useForm();
  const { values } = useFormState();
  const {record} = props;
  const PropertyListTypeFieldMap = {
    [BannerViewType.Property]: 'Недвижимость',
    [BannerViewType.Hotel]: 'Гостиница',
    [BannerViewType.Boat]: 'Лодка',
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>


    <TextInput source={'name'} label={'Название'}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <SelectInput source={'type'} label={'Тип'} choices={BannerTypeList} variant={'outlined'}  validate={required()} fullWidth={true}/>
    <SelectInput source={'viewType'} label={'Вид'} choices={BannerViewTypeList} variant={'outlined'}  validate={required()} fullWidth={true}/>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.viewType === BannerViewType.Custom && (
        <TextInput source={'link'} label={'Ссылка'}  variant={'outlined'} validate={required()} fullWidth={true}/>
      )}
    </FormDataConsumer>

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.viewType === BannerViewType.Restaurants && (
        <ReferenceInput
          label={'Ресторан'}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="restaurantId" reference="restaurant"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceInput>
      )}
    </FormDataConsumer>

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.viewType === BannerViewType.Events && (
        <ReferenceInput
          label={'Событиe'}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="eventId" reference="event"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceInput>
      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.viewType === BannerViewType.Entertainment && (
        <ReferenceInput
          label={'Экскурсия'}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="entertainmentId" reference="entertainment"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceInput>
      )}
    </FormDataConsumer>

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => [BannerViewType.Property, BannerViewType.Hotel, BannerViewType.Boat].includes(formData.viewType)  && (
        <ReferenceInput
          label={PropertyListTypeFieldMap[formData.viewType]}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="propertyId" reference="property"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceInput>
      )}
    </FormDataConsumer>
    <DateTimeInput
      label="Время публикации"
      source="publishedAt"
      variant={'outlined'}
      fullWidth={true}
    />

    <MediaInput source={'image'} label={'Картинка'} />
    <MediaInput source={'imageMobile'} label={'Картинка Моб. версия'} helperText={'Картинка для мобильной версии (необязательно)'} />
    <TextInput source={'adminNote'} multiline={true} label={'Заметка для админа'} variant={'outlined'} validate={required()} fullWidth={true}/>

  </FormTab>)
}

export const BannerForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
