import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { CreateButton } from 'react-admin'

const EmptyList = ({ basePath, title = 'Список пуст',hasCreate, description, buttonText }: any) => (
  <Box textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">
      {description}
    </Typography>
      {hasCreate && <CreateButton label={buttonText}   basePath={basePath} />}
  </Box>
)
EmptyList.defaultProps = {
    hasCreate: true
}
export default EmptyList
