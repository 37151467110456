import React, {useRef, useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput
} from "react-admin";

import {makeStyles} from '@material-ui/core/styles'
import {Box} from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'

import IconButton from '@material-ui/core/IconButton'

import CloseIcon from "@material-ui/icons/Close";
const pretty = require('pretty');

const useStyles = makeStyles({
  close:{
    position: 'fixed',
    top: '25px',
    right: '25px'
  },
  content: {
    position: 'relative',
    alignItems: 'stretch',
    overflow: 'hidden',
    maxHeight: '100%',
    height: '100%',
  },
  form: {
    maxHeight: '100%',
    display: 'flex',
    height: '100%',
    '& > div': {
      overflow: 'hidden',
      height: '100%',
    }
  }

});

export const ModalEditorPreview = (props) => {
  const classes = useStyles()
  const {record, html} = props;

  return <Dialog
    fullScreen
    fullWidth
    open={props.isShown}
    onClose={props.onClose}
    aria-label={props.title}
  >
    <Box className={'ql-editor-preview-container'}>
      <Box className={'ql-editor-preview'}  flex={'1'} overflow={'auto'} dangerouslySetInnerHTML={{__html: html}}/>
    </Box>
    <div className={classes.close}>
    <IconButton onClick={props.onClose}>
      <CloseIcon />
    </IconButton>
    </div>
  </Dialog>


}
