import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslate } from "react-admin";
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-html";

const CodeInput = ({ ...fieldProps }) => {

  const {
    options,
    label,
    source,
    format,
    parse,
    resource,
    className,
    providerOptions,
    validate
  } = fieldProps;
  const translate = useTranslate()
  const { input, meta, isRequired } = useInput({ source, format,
    parse,validate });

  const { touched, error, ...rest } = meta;

  return (
    <AceEditor
      mode="html"
      theme="monokai"
      onChange={input.onChange}
      value={input.value}
      name="UNIQUE_ID_OF_DIV"
      fontSize={14}
      height={'100%'}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
      editorProps={{ $blockScrolling: true }}

      />
)
}

CodeInput.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,

};

CodeInput.defaultProps = {
  input: {},
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',

};
export default CodeInput;
