import * as React from 'react';
import {FC} from 'react';
import {
  Edit,
  EditProps,
} from 'react-admin';
import {PropertyForm} from './PropertyForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Недвижимость {record.name}</span> : null;

const PropertyEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} mutationMode="pessimistic" title={<Title/>}>
     <PropertyForm isEdit/>
    </CustomEdit>
  );
};
export default PropertyEdit;