import * as React from 'react';
import {FC} from 'react';
import {Filter, List, TextInput, ReferenceArrayInput, AutocompleteInput, ReferenceInput, ListProps, AutocompleteArrayInput, BooleanField, ArrayField, Datagrid, ReferenceField, ChipField, SingleFieldList, TextField, SelectField, BooleanInput, DateField, SimpleForm, SelectInput} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import LinkField from 'src/components/fields/LinkField'
import PriceField from 'src/components/fields/PriceField'
import CustomChipField from 'src/components/fields/CustomChipField'
import LinkSite from 'src/components/fields/LinkSite'
import {EntityStatusList} from 'src/types';


const exporter = posts => {
  return csvExporter({
    columns: {
      'published': 'Опубликовано',
     'name': 'Название',
      'category': 'Категория',
      'seoTitle': 'SEO Title',
      'seoDescription': 'SEO Description',
      'seoKeywords': 'SEO Keywords',
      'address': 'Адрес',
      'site': 'Сайт',
      'rating': 'Рейтинг',
      'averageBill': 'Ср. чек',
      'chefName': 'Имя шефа',
      'tags': 'Теги',
      'totalViews': 'Просмотры',
      'totalLikes': 'Лайки',
      'publishedAt': 'Публикация',
      'adminUpdatedAt': 'Обновлено',
      'admin': 'Админ'

    },
    data:  posts.map(item => ({
      ...item,
      admin: item.admin?.name || '',
      tags: item.tags?.map(i => i.name)?.join(', ') || '',
      published: item.published ? 'Да' : 'Нет',
      showMain: item.showMain ? 'Да' : 'Нет',
      createdAt: formatCsvDate(item.createdAt),
      adminUpdatedAt: formatCsvDate(item.adminUpdatedAt),
      publishedAt: formatCsvDate(item.publishedAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Events'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <LinkSite source={''} type={'events'}/>

    <ReferenceInput label="Категория" source="eventCategoryId" reference="event" alwaysOn={true} resettable={true}
                    variant={'outlined'}
                    filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                    sort={{field: 'name', order: 'ASC'}}
                    fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name}`}/>
    </ReferenceInput>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>
    <SelectInput
        source="status"
        label={'Статус'}
        choices={EntityStatusList}
        variant={'outlined'} 
      />
    <TextInput source="seoTitle||$contL" label={'SEO Title'} variant={'outlined'}/>
    <TextInput source="seoDescription||$contL" label={'SEO Description'} variant={'outlined'}/>
    <TextInput source="seoKeywords||$contL" label={'SEO Keywords'} variant={'outlined'}/>
    <ReferenceArrayInput
      label={'Теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tags.id||$in" reference="tag"
      format={users => (users || []).map((club => club && club.id ? club.id : club))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name"/>
    </ReferenceArrayInput>
    <ReferenceInput
      allowEmpty={false}
      label="Город" source="cityId" reference="city" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" />
    </ReferenceInput>
    <ReferenceArrayInput
      label={'Теги Спец размещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTags.id||$in" reference="tag"
      format={users => (users || []).map((club => club && club.id ? club.id : club))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''}/>
    </ReferenceArrayInput>

    <TextInput source="site||$contL" label={'Сайт'} variant={'outlined'}/>
    <TextInput source="totalViews||$lte" label={'Просмотров меньше'} variant={'outlined'}/>
    <TextInput source="totalViews||$gte" label={'Просмотров больше'} variant={'outlined'}/>
    <TextInput source="totalLikes||$lte" label={'Лайков меньше'} variant={'outlined'}/>
    <TextInput source="totalLikes||$gte" label={'Лайков больше'} variant={'outlined'}/>
    <TextInput source="totalFeedbacks||$lte" label={'Отзывов меньше'} variant={'outlined'}/>
    <TextInput source="totalFeedbacks||$gte" label={'Отзывов больше'} variant={'outlined'}/>
    <TextInput source="rating||$lte" label={'Рейтинг меньше'} variant={'outlined'}/>
    <TextInput source="rating||$gte" label={'Рейтинг больше'} variant={'outlined'}/>
    <ReferenceInput
      allowEmpty={false}
      label="Админ" source="adminId" reference="admin" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{field: 'name', order: 'ASC'}}
      fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name}`}/>
    </ReferenceInput>
  </Filter>
)

const EventList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="События"
      empty={<EmptyList title={'Нет события'} description={'Вы можете добавить событие'} buttonText={'Добавить событие'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source="published" label={'П'}/>
      <SelectField
        source="status"
        label={'Статус'}
        choices={EntityStatusList}
      />
      <ArrayField source="tags" label={'Теги'}>
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ArrayField>
      <TextField source="name" label={'Название'}/>
      <TextField source="address" label={'Адрес'}/>
      <TextField source="rating" label={'Рейтинг'}/>
      <ArrayField source="spPlacementTags" label={'Теги спец размещения'}>
        <SingleFieldList>
          <CustomChipField source="name" render={i => i?.placeholderName || i?.name || ''}/>
        </SingleFieldList>
      </ArrayField>
      <LinkField source="totalFeedbacks" label={'Отзывы'}
                 link={'/feedbacks?displayedFilters={"eventId":true}&filter={"eventId":$id}'}/>
      <TextField source="totalViews" label={'Просмотры'}/>
      <LinkField source="totalLikes" label={'Лайки'}
                 link={'/like?displayedFilters={"eventId":true}&filter={"eventId": $id}'}/>

      <DateField source="publishedAt" label={'Время публикации'} showTime={true} />
      <DateField source="adminUpdatedAt" label={'Обновлено'} showTime={true} />

      <ReferenceField label="Админ" source="adminId" reference="admin">
        <TextField source="name"/>
      </ReferenceField>
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default EventList;
