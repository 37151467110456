import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm} from 'react-final-form'

import {
  BooleanInput,
  TabbedForm,
  Datagrid,
  ReferenceInput,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  AutocompleteInput,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';
import {formatUserName, slug} from 'src/utils/formatters'
import {FeedbackMarkList, FeedbackStatusList} from 'src/types'

const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (
    <FormTab
      label="Информация"
      path={'benefit'}
      {...props}>
      <SelectInput source={'status'} label={'Статус'} variant={'outlined'} choices={FeedbackStatusList} validate={required()} fullWidth/>

      <ReferenceInput  label="Ресторан" source="restaurantId" reference="restaurant"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       allowEmpty={false}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={(record) => `${record?.name}`} />
      </ReferenceInput>
      <ReferenceInput  label="Событие" source="eventId" reference="event"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       allowEmpty={false}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={(record) => `${record?.name}`} />
      </ReferenceInput>
      <ReferenceInput  label="Экускурсия" source="entertainmentId" reference="entertainment"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       allowEmpty={false}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={(record) => `${record?.name}`} />
      </ReferenceInput>
      <ReferenceInput  label="Пользователь" source="userId" reference="user"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'phone||$contL': searchText} : {})})}
                       sort={{field: 'phone', order: 'ASC'}}
                       allowEmpty={false}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={formatUserName} />
      </ReferenceInput>
      <SelectInput source={'mark'} label={'Оценка'} variant={'outlined'} choices={FeedbackMarkList} validate={required()} fullWidth/>
      <TextInput source={'description'} label={'Отзыв'}  variant={'outlined'} fullWidth/>

    </FormTab>
  )
}

export const FeedbackForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
