import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SimpleForm, TextField
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import AssetField from 'src/components/fields/AssetField'
import LinkField from 'src/components/fields/LinkField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'name': 'Название',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'TagCategory'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>


  </Filter>
)

const TagCategoryList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Категории тегов"
      empty={<EmptyList title={'Нет категорий тегов'} description={'Вы можете добавить категорию тегов'} buttonText={'Добавить категорию тегов'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <TextField source={'name'} label={'Название'}/>
      <LinkField source="tags"  value={'Теги'} label={'Теги'}
                 link={'/tag?filter={"tagCategoryId": $id}'}/>

      <DateField source="createdAt" label={'Создано'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default TagCategoryList;
