import PersonIcon from '@material-ui/icons/Person';
import ReservationList from './ReservationList'
import ReservationEdit from './ReservationEdit'
import ReservationCreate from './ReservationCreate'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
export default {
  create: ReservationCreate,
  edit: ReservationEdit,
  list: ReservationList,
  icon: ConfirmationNumberIcon,
};