import * as React from 'react';
import {
  BooleanInput,
  FormTab,
  required,
  TextInput,
  TabbedForm
} from 'react-admin';
import {useForm, useFormState} from 'react-final-form'
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'


const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}

const InfoTab = props => {
  const form = useForm();
  const { values } = useFormState();
  const {record} = props;

  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (<FormTab
    label="Информация"
    path={'info'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>
    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <RichTextInput source={'body'} multiline={true} label={'Краткое описание'} variant={'outlined'} fullWidth={true} validate={required()}/>

  </FormTab>)
}

export const PageForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      <SeoTab/>
    </TabbedForm>
  );
};
