import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames';
function clamp(val, min, max) {
  return val < min ? min : val > max ? max : val;
}


const useStyles = makeStyles({
  root: {

    overflow: 'hidden',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0
  },
  point:{
    position: 'relative',
    zIndex: 3,
  width: '40px',
  height: '40px',
  margin: '-20px 0 0 -20px',
borderRadius: '50%',
  border: '2px solid rgba(0,0,0, .8)',
  background: 'rgba(255,255,255, .75)',
  cursor: 'grab',
  opacity: .5,
  transition: 'opacity .1s ease-out',
    '&:hover': {
      opacity: 1,
    }
  },
  preview:{
    position: 'absolute',
    zIndex: 2,
top: 0,
left: 0,
  },
  overlay:{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(#000, .75)'
  },
  pointDragging: {
    opacity: 1
  }
});



interface Props{
  onChange: (x, y) => void,
  height: number,
  width: number,
  preview?: string,
  x?: number,
  y?: number,
}

 const FocalPoint = (props: Props) => {
  const {width, height, x, y, preview} = props;
   const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const [pointX, setPointX ] = useState(width  * x);
  const [pointY, setPointY] =useState( height * y);
  const [ previewWidth, setPreviewWidth] = useState( 0);
  const [previewHeight, setPreviewHeight] =  useState(0);
   const startPoint = useRef(null);
   const startPos = useRef(null);
   const prevPos = useRef(null);
   console.log("FocatPoint", x, y, width, height)
  useEffect(() => {
    if(preview) {
      setPreview(preview)
    }
  }, [preview])

  const  onDragStart = (ev) => {
    ev.preventDefault();

    startPoint.current = { x: pointX, y: pointY };
    startPos.current = getPointer(ev);
    setDragging(true);
    window.addEventListener('mousemove', onDragMove);
    window.addEventListener('touchmove', onDragMove);
    window.addEventListener('mouseup', onDragEnd);
    window.addEventListener('touchend', onDragEnd);
  }

   const  onDragMove = (ev)  => {
    const pos = getPointer(ev);
    const dX = startPos.current.x - pos.x;
    const dY = startPos.current.y - pos.y;

    prevPos.current = pos;
    setPosition(startPoint.current.x - dX, startPoint.current.y - dY);

  }

   const onDragEnd = () => {
     setDragging(false);
     window.removeEventListener('mousemove', onDragMove);
     window.removeEventListener('touchmove', onDragMove);
     window.removeEventListener('mouseup', onDragEnd);
     window.removeEventListener('touchend', onDragEnd);
  }

   const getPointer = (ev)  =>  {
    if (ev.touches || ev.changedTouches) {
      const t = ev.touches[0] || ev.changedTouches[0];
      return { x: t.pageX, y: t.pageY };
    }

    return { x: ev.pageX, y: ev.pageY };
  }

   const setPosition = (x, y) =>  {
    const clampedX = clamp(x, 0, props.width);
    const clampedY = clamp(y, 0, props.height);

    if (clampedX === pointX && clampedY === pointY) return;

    setPointX(clampedX);
    setPointY(clampedY);


    if (props.onChange) {
      props.onChange(
        Math.round(100 / props.width * clampedX),
        Math.round(100 / props.height * clampedY),
      );
    }
  }

  const setPreview = (preview) =>  {
    const s = preview.split(':');

    let width = parseInt(s[0], 10);
    let height = parseInt(s[1], 10);

    let ratio;
    if (width < props.width && height < props.height) {
      ratio = props.width / width;
      width *= ratio;
      height *= ratio;
    }

    if (width > props.width) {
      ratio = props.width / width;
      width *= ratio;
      height *= ratio;
    }

    if (height > props.height) {
      ratio = props.height / height;
      width *= ratio;
      height *= ratio;
    }

    width = Math.round(width);
    height = Math.round(height);
    setPreviewWidth(width);
    setPreviewHeight(height);

  }

  const renderOverlays = () => {
    const height = previewHeight;
    const width = previewWidth;
    const x = clamp(pointX - (width / 2), 0, props.width - width);
    const y = clamp(pointY - (height / 2), 0, props.height - height);

    const previewStyle = { width, height, transform: `translate(${x}px, ${y}px)` };
    let overlay1Style;
    let overlay2Style;

    if (width === props.width) {
      overlay1Style = { transform: `translate(0, ${y - props.height}px)` };
      overlay2Style = { transform: `translate(0, ${y + height}px)` };
    } else {
      overlay1Style = { transform: `translate(${x - props.width}px, 0)` };
      overlay2Style = { transform: `translate(${x + width}px, 0)` };
    }

    return [
      <div key={0} className="focal__preview" style={previewStyle} />,
      <div key={1} className="focal__overlay" style={overlay1Style} />,
      <div key={2} className="focal__overlay" style={overlay2Style} />,
    ];
  }

  console.log("pointX", pointX, pointY)
   const pointStyles = { transform: `translate(${pointX}px, ${pointY}px)` };

   return (
     <div className={classnames(classes.root)} style={{ width, height }}>
       <div
         className={classnames(classes.point, {[classes.pointDragging]: dragging})}
         onMouseDown={onDragStart}
         onTouchStart={onDragStart}
         style={pointStyles}
       />
       {preview && renderOverlays()}
     </div>
   );

}
FocalPoint.defaultProps = {
  x: 0.5,
  y: 0.5
}
export default FocalPoint