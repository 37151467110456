import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Pagination,
  FunctionField,
  ReferenceField,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  useDataProvider,
  DeleteButton,
  useRefresh,
  TabbedForm,
  TextField,
  ReferenceManyField,
  TextInput, SelectField,
} from 'react-admin';

import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import OrderButtons from 'src/components/list/OrderButtons'
import {EventPartForm} from 'src/resources/event/event-parts/EventPartForm'
import AssetField from 'src/components/fields/AssetField'
import PriceField from 'src/components/fields/PriceField'
import {MenuItemForm} from 'src/resources/restaurant/menu-items/MenuItemForm'


interface Props {
  record?: any;
}

export const EventPartList = (props) => {
  const {record} = props;
  const data = (record.benefits || []).sort((a, b) => (a.sort) - (b.sort));
  return (

    <ReferenceManyField reference="event-part" target="eventId" addLabel={false} sort={{field: 'startTime', order: 'ASC'}}
                        perPage={50}>
      <RelatedList record={record}
                   emptyTitle={'Нет расписания'}
                   actions={<ModalCreateButton modal={<EventPartForm mainRecord={record}/>}/>}
                   createButton={<ModalCreateButton modal={<EventPartForm mainRecord={record}/>}/>}>
        <DateField source="startTime" label={'Время'} showTime={true} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <TextField source={'place'} label={'Что будет'}/>
        <PriceField source={'minPrice'} label={'Мни. цена'}/>
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <ModalEditButton modal={<EventPartForm mainRecord={record}/>}/>
        <DeleteButton label={''} mutationMode="pessimistic" redirect={false}/>
      </RelatedList>
    </ReferenceManyField>)
}