import * as React from 'react';
import {FC} from 'react';
import {
  Create,
  EditProps,
} from 'react-admin';
import {BoatForm} from 'src/resources/property/boat/BoatForm'
import {PropertyType} from 'src/types'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новая лодка</span>

const BoatCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <BoatForm redirect={'show'} initialValues={{type: PropertyType.Boat}}/>
    </Create>
  );
};

export default BoatCreate;