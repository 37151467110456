import {format} from 'date-fns'
import {downloadCSV} from 'react-admin'
import jsonExport from 'jsonexport/dist';
interface CsvExporterProps{
  columns: any
  data: any[]
  fileName: string
}
export const formatCsvDate = (date, onlyDate?: boolean ) => {
  if(!date){
    return;
  }
  return  format(new Date(date), onlyDate ? 'dd.MM.yy' : 'dd.MM.yy HH:mm')
}
export const formatListValue = (value, list: {id: string | number, name: string}[] ) => {
  return list.find(i => i.id === value)?.name;
}

export const csvExporter = ({columns, data, fileName}: CsvExporterProps) => {
  const keys = Object.keys(columns);
  const dataToExport = data.map(item => {
    const res = {};
    Object.keys(item).filter(i => keys.includes(i)).forEach(i => {
      res[i] = item[i];
    })
    return res;
  });

  jsonExport([ columns, ...dataToExport], {
    forceTextDelimiter: true,
    rowDelimiter: ';',
    includeHeaders: false,
  }, (err, csv) => {
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, fileName); // download as 'posts.csv` file
  });
}
