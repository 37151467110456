import PersonIcon from '@material-ui/icons/Person';
import PageList from './PageList'
import PageEdit from './PageEdit'
import PageCreate from './PageCreate'

export default {
  create: PageCreate,
  edit: PageEdit,
  list: PageList,
  icon: PersonIcon,
};