import * as React from 'react';
import {
  SimpleForm,
  SelectInput,
  TextInput,
  ReferenceInput, AutocompleteInput, Filter, SelectField, required,
} from 'react-admin';



export const UserForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source={'phone'} label={'Телефон'} variant={'outlined'} disabled={props.isEdit} validate={required()}/>
      <TextInput source={'firstName'} label={'Имя'} variant={'outlined'}/>
      <TextInput source={'lastName'} label={'Фамилия'} variant={'outlined'}/>

    </SimpleForm>
  );
};
