import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {ArticleForm} from 'src/resources/article/ArticleForm'
import {getTranslation} from 'src/utils/translation'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Статья {record.name}</span> : null;

const ArticleEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} mutationMode="pessimistic" title={<Title/>}>
    <ArticleForm/>
    </CustomEdit>
  );
};
export default ArticleEdit;