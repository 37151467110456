import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid, ReferenceInput, AutocompleteInput, ReferenceField,
  BooleanInput, DateField, SimpleForm, TextField, SelectInput, required, FormTab, SelectField
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import AssetField from 'src/components/fields/AssetField'
import OrderButtons from 'src/components/list/OrderButtons'
import {ArticleGroupTypeList} from 'src/types'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'published': 'Опубликовано',
      'group': 'Группа',
      'name': 'Название',
      'slug': 'URI',
      'seoTitle': 'SEO Title',
      'seoDescription': 'SEO Description',
      'seoKeywords': 'SEO Keywords',
      'totalLikes': 'Кол-во лайков',
      'articlesCount': 'Кол-во статей',
      'description': 'Описание',
      'adminUpdatedAt': 'Обновлено',
      'admin': 'Админ'

    },
    data:  posts.map(item => ({
      ...item,
      group: ArticleGroupTypeList.find(i => i.id === item.group)?.name || '',
      admin: item.admin?.name || '',
      published: item.published ? 'Да' : 'Нет',
      createdAt:  formatCsvDate(item.createdAt),
      adminUpdatedAt:  formatCsvDate(item.adminUpdatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'ArticleCategory'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <SelectInput source={'group'} label={'Группа'} choices={ArticleGroupTypeList} variant={'outlined'} fullWidth={true}/>

    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="slug||$contL" label={'URI'} variant={'outlined'}/>
    <TextInput source="key||$contL" label={'Ключ'} variant={'outlined'}/>
    <TextInput source="description||$contL" label={'Описание'} variant={'outlined'}/>
    <TextInput source="seoTitle||$contL" label={'SEO title'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords||$contL" label={'SEO keywords'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription||$contL" label={'SEO description'}  variant={'outlined'} fullWidth={true}/>
    <ReferenceInput
      allowEmpty={false}
      label="Админ" source="adminId" reference="admin" variant={'outlined'}
      filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText} : {}) })}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name}`}/>
    </ReferenceInput>

  </Filter>
)

const ArticleCategoryList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Категории статей"
      empty={<EmptyList title={'Нет категорий статей'} description={'Вы можете добавить категорию статьи'} buttonText={'Добавить категорию статьи'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <SelectField source={'group'} label={'Группа'} choices={ArticleGroupTypeList}/>
      <TextField source={'name'} label={'Название'}/>
      <TextField source="slug" label={'URI'}/>
      <TextField source="description" label={'Описание'} />
      <OrderButtons source="sort" label={'Сортировка'} sortable={false}/>
      <TextField source="totalLikes" label={'Кол-во лайков'} />
      <TextField source="articlesCount" label={'Кол-во статей'} />
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />

      <DateField source="adminUpdatedAt" label={'Обновлено'} showTime={true} />
      <ReferenceField label="Админ" source="adminId" reference="admin">
        <TextField source="name" />
      </ReferenceField>


    </Datagrid>
    </List>
  );
}

export default ArticleCategoryList;
