import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh, FormDataConsumer,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput, FormTab, SimpleForm
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {ArticleTypeList, RestaurantNewsType, RestaurantNewsTypeList} from 'src/types'

export const RestaurantNewsForm = (props) => {
  const { record, mainRecord} = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    if (record?.id) {
      await dataProvider.update('restaurant-news', {
        id: record.id,
        data: {
          restaurantId: mainRecord.id,
          ...data
        },
        previousData: record
      });
    } else {
      await dataProvider.create('restaurant-news', {
        data: {
          restaurantId: mainRecord.id,
          ...data
        }
      });
    }

    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={record ? 'Редактировать Новость' : 'Добавить Новость'}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <TextInput source={'name'}  label={'Название'} variant={'outlined'} fullWidth={true} validate={required()}/>
    <SelectInput source={'type'} label={'Тип'} choices={RestaurantNewsTypeList} variant={'outlined'} validate={required()}  defaultValue={RestaurantNewsType.Article} fullWidth={true}/>
    <MediaInput source={'image'} label={'Картинка'} />
    <RichTextInput source={'text'} label={'Текст'} />
  </ModalForm>
}
