import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import LinkField from 'src/components/fields/LinkField'
import {ArticleEntityListType, ArticleEntityListTypeList, ArticleGroupTypeList, ArticleTypeList} from 'src/types'
import LinkSite from 'src/components/fields/LinkSite'
import CustomChipField from 'src/components/fields/CustomChipField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'published': 'Опубликовано',
      'name': 'Название',
      'type': 'Тип',
      'entityListType': 'Список',
      'category': 'Категория',
      'seoTitle': 'SEO Title',
      'seoDescription': 'SEO Description',
      'seoKeywords': 'SEO Keywords',

      'userEmail': 'Пользователь(Email)',
      'tags': 'Теги',
      'totalViews': 'Просмотры',
      'totalLikes': 'Лайки',
      'publishedAt': 'Публикация',
      'adminUpdatedAt': 'Обновлено',
      'admin': 'Админ'

    },
    data:  posts.map(item => ({
      ...item,
      admin: item.admin?.name || '',
      category: item.category?.name || '',
      user: item.user?.nickname || '',
      userEmail: item.user?.email || '',
      type: ArticleTypeList.find(i => i.id === item.type)?.name || '',
      entityListType: ArticleEntityListTypeList.find(i => i.id === item.entityListType)?.name || '',
      tags: item.tags?.map(i => i.name)?.join(', ') || '',
      published: item.published ? 'Да' : 'Нет',
      createdAt: formatCsvDate(item.createdAt),
      adminUpdatedAt: formatCsvDate(item.adminUpdatedAt),
      publishedAt: formatCsvDate(item.publishedAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Affirmation'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <ReferenceInput label="Категория" source="articleCategoryId" reference="article-category" alwaysOn={true} resettable={true}
                    variant={'outlined'}
                    filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                    sort={{field: 'name', order: 'ASC'}}
                    fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.group ? `${formatListValue(record?.group, ArticleGroupTypeList)} -> ` : ``} ${record?.name}`}/>
    </ReferenceInput>
    <SelectInput source="type" label={'Тип'} choices={ArticleTypeList} variant={'outlined'}  resettable={true}/>
    <SelectInput source="type" label={'Тип'} choices={ArticleEntityListTypeList} variant={'outlined'}  resettable={true}/>

    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>
    <ReferenceArrayInput
      label={'Теги Спец размещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTags.id||$in" reference="tag"
      format={users => (users || []).map((club => club && club.id ? club.id : club))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''}/>
    </ReferenceArrayInput>
     <TextInput source="seoTitle||$contL" label={'SEO Title'} variant={'outlined'}/>
    <TextInput source="seoDescription||$contL" label={'SEO Description'} variant={'outlined'}/>
    <TextInput source="seoKeywords||$contL" label={'SEO Keywords'} variant={'outlined'}/>
    <ReferenceArrayInput
      label={'Теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tags.id||$in" reference="tag"
      format={users => (users || []).map((club => club && club.id ? club.id : club))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name"/>
    </ReferenceArrayInput>
    <TextInput source="totalViews||$lte" label={'Просмотров меньше'} variant={'outlined'}/>
    <TextInput source="totalViews||$gte" label={'Просмотров больше'} variant={'outlined'}/>
    <TextInput source="totalLikes||$lte" label={'Лайков меньше'} variant={'outlined'}/>
    <TextInput source="totalLikes||$gte" label={'Лайков больше'} variant={'outlined'}/>
    <ReferenceInput
      allowEmpty={false}
      label="Админ" source="adminId" reference="admin" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{field: 'name', order: 'ASC'}}
      fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name}`}/>
    </ReferenceInput>
  </Filter>
)

const ArticleList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Статьи"
      empty={<EmptyList title={'Нет статей'} description={'Вы можете добавить статью'} buttonText={'Добавить статью'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source="published" label={'П'}/>
      <ReferenceField label="Категория" source="articleCategoryId" reference="article-category">
        <TextField source="name"/>
      </ReferenceField>
      <ArrayField source="tags" label={'Теги'}>
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ArrayField>
      <TextField source="name" label={'Название'}/>
      <SelectField source="type" label={'Тип'} choices={ArticleTypeList}/>
      <LinkSite source={''} type={'news'}/>
      <TextField source="totalViews" label={'Просмотры'}/>
      <LinkField source="totalLikes" label={'Лайки'}
                 link={'/like?displayedFilters={"articleId":true}&filter={"articleId": $id}'}/>
      <ArrayField source="spPlacementTags" label={'Теги спец размещения'}>
        <SingleFieldList>
          <CustomChipField source="name" render={i => i?.placeholderName || i?.name || ''}/>
        </SingleFieldList>
      </ArrayField>
      <DateField source="publishedAt" label={'Время публикации'} showTime={true} />
      <DateField source="adminUpdatedAt" label={'Обновлено'} showTime={true} />
      <ReferenceField label="Админ" source="adminId" reference="admin">
        <TextField source="name"/>
      </ReferenceField>
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default ArticleList;
