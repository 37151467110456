import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh, FormDataConsumer,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput, FormTab, SimpleForm
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import {MediaInput} from 'src/components/inputs/MediaInput'

export const MenuItemForm = (props) => {
  const { record, mainRecord} = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    if (record?.id) {
      await dataProvider.update('restaurant-menu-item', {
        id: record.id,
        data: {
          restaurantId: mainRecord.id,
          ...data
        },
        previousData: record
      });
    } else {
      await dataProvider.create('restaurant-menu-item', {
        data: {
          restaurantId: mainRecord.id,
          ...data
        }
      });
    }

    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={record ? 'Редактировать меню' : 'Добавить меню'}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <TextInput source={'name'}  label={'Название'} variant={'outlined'} fullWidth={true} validate={required()}/>
    <MediaInput source={'image'} label={'Картинка меню'} validate={required()} />
    <MediaInput source={'menu'} label={'Файл меню'}   accept={["image/jpeg", "image/png", 'image/svg+xml', 'application/pdf']} validate={required()}/>
  </ModalForm>
}
