import * as React from 'react';
import {Children, ReactElement, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {FormWithRedirect, FormWithRedirectSave, RedirectionSideEffect} from 'ra-core';
import {CardContentInner, FormInput} from 'react-admin';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from './Toolbar';
import Dialog from "@material-ui/core/Dialog";

interface ModalFormProps{
  children?: ReactElement | ReactElement[]
    isShown: boolean
  onClose: () => void
  title?: string
  fullScreen?: boolean
  fullWidth?: boolean
  record?: any
  maxWidth?: any,
  contentClassName?: string
  save:  (data) => Promise<boolean>
}

const ModalForm = (props: ModalFormProps) => {
  const [saving, setSaving] = useState(false);
  const handleSave = async (data) => {
    setSaving(true);
    await props.save(data);
    setSaving(false);
  }
 return  <Dialog
   fullScreen={props.fullScreen}
    fullWidth={props.fullWidth}
   maxWidth={props.maxWidth}
    open={props.isShown}
    onClose={props.onClose}
    aria-label={props.title}
  >
    {props.title && <DialogTitle>{props.title}</DialogTitle>}
    <FormWithRedirect
      {...props}
      save={handleSave}
      redirect={false}
      saving={saving}
      render={formProps => <ModalFormView {...formProps as any} handleClose={props.onClose}/>}

    />
  </Dialog>
}
ModalForm.defaultTypes = {
  fullWidth: true
}

interface ModalFormViewProps{
  basePath?: string,
  children?: ReactElement | ReactElement[],
  className?: string
  handleClose?: () => void,
  handleSubmit?: (data) => void // passed by react-final-form
  invalid?: boolean
  pristine?: boolean
  record?: any
  resource?:  string
  redirect?: RedirectionSideEffect;
  save?: FormWithRedirectSave;
  contentClassName?: string
  saving?: boolean
  submitOnEnter?: boolean
  toolbar?: ReactElement
  undoable?: boolean
  validate?: (data) => void
  saveButtonLabel?: string
  variant?: string
  margin?: string
}
const ModalFormView = ({
                         basePath,
                         children,
                         className,
                         handleSubmit,
                         handleClose,
                         invalid,
                         margin,
                         pristine,
                         record,
                         redirect,
                         resource,
                         saving,
                         submitOnEnter,
                         toolbar,
                         contentClassName,
                         undoable,
                         variant,
                         saveButtonLabel,
                         ...rest
                       }: ModalFormViewProps) => {;
                       // @ts-ignore
  return (
    <>

      <DialogContent className={contentClassName}>
        <form
          className={classnames('simple-form', className)}
          {...sanitizeRestProps(rest as any)}
        >
        {Children.map(
          children,
          input =>
            input && (
              <FormInput
                basePath={basePath}
                input={input}
                record={record}
                resource={resource}
                variant={input.props.variant || variant}
                margin={input.props.margin || margin}
              />
            )
        )}

        </form>

  </DialogContent>
    <DialogActions>
      {toolbar &&
      React.cloneElement(toolbar, {
        handleClose,
        basePath,
        saveButtonLabel,
        handleSubmit,
        invalid,
        pristine,
        record,
        redirect,
        resource,
        saving,
        submitOnEnter,
        undoable,
      })}
    </DialogActions>
    </>

)
}


ModalFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar/>,
};

const sanitizeRestProps = ({
                             anyTouched,
                             array,
                             asyncBlurFields,
                             asyncValidate,
                             asyncValidating,
                             autofill,
                             blur,
                             change,
                             clearAsyncError,
                             clearFields,
                             clearSubmit,
                             clearSubmitErrors,
                             destroy,
                             dirty,
                             dirtyFields,
                             dirtyFieldsSinceLastSubmit,
                             dirtySinceLastSubmit,
                             dispatch,
                             form,
                             handleSubmit,
                             hasSubmitErrors,
                             hasValidationErrors,
                             initialize,
                             initialized,
                             initialValues,
                             pristine,
                             pure,
                             redirect,
                             reset,
                             resetSection,
                             save,
                             setRedirect,
                             submit,
                             submitError,
                             submitErrors,
                             submitAsSideEffect,
                             submitFailed,
                             submitSucceeded,
                             submitting,
                             touch,
                             translate,
                             triggerSubmit,
                             undoable,
                             untouch,
                             valid,
                             validate,
                             validating,
                             _reduxForm,
                             ...props
                           }) => props;

export default ModalForm;
