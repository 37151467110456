import * as React from 'react';
import {FC} from 'react';
import {
  Edit,
  EditProps,
} from 'react-admin';
import {BoatForm} from 'src/resources/property/boat/BoatForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Лодка {record.name}</span> : null;

const BoatEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} mutationMode="pessimistic" title={<Title/>}>
      <BoatForm isEdit/>
    </CustomEdit>
  );
};
export default BoatEdit;