import * as React from 'react';
import { FC } from 'react';
import get from 'lodash/get'
import {PublicFieldProps, InjectedFieldProps, FunctionField} from 'react-admin';
import {formatPhone, formatPrice} from 'src/utils/formatters'
import {Typography} from '@material-ui/core'

const PriceField: FC<PublicFieldProps & InjectedFieldProps> = props => {
  const price = get(props.record, props.source);
  return (<Typography variant={'body2'}>{ typeof price === 'undefined' ? '' : formatPrice(price)}</Typography>)
};


export default PriceField;