import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'

const exporter = posts => {
  return csvExporter({
    columns: {
      'published': 'Опубликовано',
      'name': 'Название',
      'slug': 'URI',

    },
    data:  posts.map(item => ({
      ...item,
    })),
    fileName: 'City'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>

  </Filter>
)

const CityList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Города"
      sort={{field: 'name', order: 'ASC'}}
      empty={<EmptyList title={'Нет городов'} description={'Вы можете добавить город'} buttonText={'Добавить город'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source="published" label={'П'}/>
      <TextField source="name" label={'Название'}/>
    </Datagrid>
    </List>
  );
}

export default CityList;
