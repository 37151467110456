import * as React from 'react';
import { memo, FC } from 'react';
import get from 'lodash/get';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import {InjectedFieldProps, PublicFieldProps} from 'react-admin'
import {fieldPropTypes} from 'src/components/types'

const useStyles = makeStyles(
  {
    chip: { margin: 4, cursor: 'inherit' },
  },
  { name: 'RaCustomChipField' }
);

export const CustomChipField: FC<CustomChipFieldProps> = memo(props => {
  const {
    className,
    classes: classesOverride,
    source,
    emptyText,
    render,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const classes = useStyles(props);
  const value = render ? render(record) : get(record, source);

  if (value == null && emptyText) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
      >
        {emptyText}
      </Typography>
    );
  }

  return (
    <Chip
      className={classnames(classes.chip, className)}
      label={value}
    />
  );
});

CustomChipField.defaultProps = {
  addLabel: true,
  render: null
};

CustomChipField.propTypes = {
  // @ts-ignore
  ...CustomChipField.propTypes,
  ...fieldPropTypes,
};

CustomChipField.displayName = 'CustomChipField';

export interface CustomChipFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    Omit<ChipProps, 'label'> {
  render?: (record) => string | null
}

export default CustomChipField;