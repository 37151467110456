import debounce from 'lodash/debounce';
import React, {useRef, useEffect, useCallback, ComponentProps, ReactElement} from 'react';
import Quill, { QuillOptionsStatic } from 'quill';
import { useInput, FieldTitle } from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';
import {
  FormHelperText,
  FormControl,
  InputLabel,
  PropTypes as MuiPropTypes,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datetime-picker';
import {formatISO} from 'date-fns'

import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles(
  theme => ({
   label: {

   },
    picker:{
     marginTop: '20px',
      '& .react-datetime-picker__wrapper': {
        font: 'inherit',
        color: 'currentColor',
        width: '100%',
        'zIndex': 10,
        margin: '0',
        padding: '0px 14px',
        'min-width': '0',
        background: 'none',
        'box-sizing': 'content-box',
        'animation-name': 'mui-auto-fill-cancel',
        'letter-spacing': 'inherit',
        'animation-duration': '10ms',
        '-webkit-tap-highlight-color': 'transparent',
        'height': '40px',
        'border': '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '4px'
      },
      '& .react-datetime-picker__calendar': {
        'zIndex': 10,
      }
    }
  }),
  { name: 'DateTimeInput' },
)
export interface DateTimeInputProps {
  label?: string | ReactElement;
  source: string;
    fullWidth?: boolean;

  helperText?: ComponentProps<typeof InputHelperText>['helperText'];
  record?: Record<any, any>;
  resource?: string;
  variant?: string;
  margin?: MuiPropTypes.Margin;
  [key: string]: any;
}

const DateTimeInput = (props: DateTimeInputProps) => {
  const {
    options = {}, // Quill editor options
    toolbar = true,
    fullWidth = true,
    classes: classesOverride,
    configureQuill,
    helperText,
    label,
    source,
    resource,
    variant,
    margin = 'dense',
    ...rest
  } = props;
  const classes = useStyles(props);
  const quillInstance = useRef<Quill>();
  const divRef = useRef<HTMLDivElement>();
  const editor = useRef<HTMLElement>();

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, ...rest });



  return (<div>
    <FormControl
      error={!!(touched && error)}

      className="ra-rich-text-input"
      margin={margin}
    >
      <InputLabel shrink htmlFor={id} className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>

      <DateTimePicker
        {...props}
        value={value ? new Date(value) : value}
        disableClock={true}
        className={classes.picker}
        onChange={(value) => {
          console.log("onChange", value)
          if (!value) {
            onChange(null)
            return
          }
          try {
            if (isNaN(value.getTime()) || value.getFullYear() < 1000) {
              return
            }

            console.log("CallOcnahgne");
            onChange(formatISO(value))
          } catch (e) {
            console.error(e)
          }
        }}
        format={'dd.MM.y HH:mm'}
      />
      <FormHelperText
        error={!!error}
        className={!!error ? 'ra-rich-text-input-error' : ''}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched}
        />
      </FormHelperText>
    </FormControl>
    </div>
  );
};

DateTimeInput.propTypes = {
  // @ts-ignore
  label: PropTypes.any,
  options: PropTypes.object,
  source: PropTypes.string,
  fullWidth: PropTypes.bool,
  configureQuill: PropTypes.func,
};

export default DateTimeInput;