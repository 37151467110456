import * as React from 'react';
import {Children, ReactElement, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { stringify } from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  CardContentInner,
  FormInput,
  Identifier,
  linkToRecord,
  NumberField,
  useDataProvider,
  useGetList,
  useQuery
} from 'react-admin';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Pagination from '@material-ui/lab/Pagination';
import GridListTile from '@material-ui/core/GridListTile'
import {Link} from 'react-router-dom'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import MuiGridList from '@material-ui/core/GridList'
import {makeStyles} from '@material-ui/core/styles'
import {Button} from '@material-ui/core'
import {useDropzone} from 'react-dropzone'
import {getMediaPath} from 'src/utils/media'
import Cookies from 'js-cookie'
import axios from 'axios'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'
import withWidth from '@material-ui/core/withWidth'
import {MediaInput} from 'src/components/inputs/MediaInput'

interface ModalMediaProps{
    isShown?: boolean
  onClose?: () => void
  title?: string
  fullScreen?: boolean
  fullWidth?: boolean
}

interface ModalMediaViewProps{
  onSelect?: (record) => void
  onClose?: () => void,
  maxSize?,
  accept?
  width?
}

const ModalMedia = (props: ModalMediaProps & ModalMediaViewProps) => {


 return  <Dialog
   fullScreen={props.fullScreen}
    fullWidth={props.fullWidth}
    open={props.isShown}
    onClose={props.onClose}
    aria-label={props.title}
  >
   {props.isShown && <ModalMediaView {...props}/>}
  </Dialog>
}
ModalMedia.defaultTypes = {
  fullWidth: true,
    accept: ["image/jpeg", "image/png", 'image/svg+xml'],


}


const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      padding: '8px',
      display: 'flex',
      flexDirection: 'column',
maxHeight: 'calc(100% - 64px)',
overflowY: 'auto',
    },
    gridList: {
      margin: 0,

    },
    tileBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
      backgroundColor: theme.palette.grey[300],
      height: '100%',
    },
    activeItem: {
      border: '3px solid red'
    },
    dragActive:{

    },
    dropZone: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 10,
      bottom: '0px',
      right: '0px',
      background: 'rgba(0,0,0, 0.5)',
      color: 'white'
    },
    dropZoneWrapper:{
      position: 'absolute',
      top: '15px',
      bottom: '15px',
      left: '15px',
      right: '15px',
      border: '3px white dashed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropZoneText: {

    },

    uploading: {
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 10,
      bottom: '0px',
      right: '0px',
      background: 'rgba(0,0,0, 0.5)',
    },


    btnCancel: {
      marginTop: '20px',
      background: theme.palette.error.main
    },
    dialogTitle:{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 24px'
    },
    dialogTitleActions:{
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }),
  { name: 'RaFileInput' },
)
const ModalMediaView = (props: ModalMediaViewProps) => {
  const {onSelect, maxSize, accept} = props;
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const classes = useStyles(props)
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [currentRecord, setCurrentRecord] = useState(null);
  const dataProvider = useDataProvider();
  const abortController = useRef(null);
  const loadPage = async (page) => {
    setPage(page);
   const res = await dataProvider.getList('asset', {
      pagination: {page, perPage: 30},
      sort: {field: 'id', order: 'DESC'},
      filter: {}
    })
    console.log("LoadPage", res);
    setData(res.data);
    setTotal(res.total);
  }
  useEffect(() => {
    loadPage(1)
  }, [])
  const handleChangePage = async (e, page: number) => {
    loadPage(page);
  }
  const handleSelectAsset = (record) => {

    setCurrentRecord(currentRecord && currentRecord.id === record.id ? null : record);
  }
  const handleChoose = () => {
    console.log("handleChoose",handleChoose)
    onSelect(currentRecord);
  }
  const handleUploadCancel = () =>
    abortController.current && abortController.current.cancel();

  const uploadFiles = async (files: File[]) => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const token = Cookies.get('auth-token')

    abortController.current = source;
    for(const file of files) {


      const form = new FormData();
      form.append('file', file);
      const axiosOptions = {
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        cancelToken: source.token,
        onUploadProgress: e => {
          // Progress in processing uploads
          setProgress(e.loaded / e.total);
        },
      };
      const uploadRes = await axios.put(`${process.env.REACT_APP_API_URL || ''}/admin/api/asset/upload`, form, axiosOptions);
      console.log("uploadRes", uploadRes);

    }
    /*
    return dataProvider.mutate({
      mutation: createAssets,
      variables: {
        input: files.map(file => ({file}))
      },
      context: {
        useMultipart: true,
        fetchOptions: {
          onUploadProgress: (progress => {
            console.info(progress);
            setProgress(progress);
          }),
          signal: controller.signal
        }
      }
    })*/
  }
  const onDrop = async (newFiles) => {
    setIsUploading(true);
    await uploadFiles(newFiles);
    setIsUploading(false);
      console.log("OnDrop", newFiles);
    await loadPage(1)
  }
  const getColsForWidth = (width: Breakpoint) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 3;
    if (width === 'lg') return 5;
    return 6;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    multiple: true,
    onDrop,
  })
  return (<div className={classnames(classes.root, {[classes.dragActive]:isDragActive})} >
      <div className={classes.dialogTitle}><Typography  variant="h6">Файлы</Typography>
        <div className={classes.dialogTitleActions} {...getRootProps()}>
          <Button variant="contained" color="primary" >
        Загрузить файл   <input {...getInputProps()} /></Button>
      </div>
  </div>
    <DialogContent>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(props.width as Breakpoint)}
        className={classes.gridList}
      >

    {data.map((item) => (
        <GridListTile
          className={classnames({[classes.activeItem]: currentRecord?.id === item.id})}
          onClick={(e) => {
            e.preventDefault();
            handleSelectAsset(item);
          }}
          component={Link}
          to={''}

          key={item.id}
        >
          <img src={`${getMediaPath(item.source)}?preset=small&fpx=${item.focalPoint?.x || '0.5'}&fpy=${item.focalPoint?.y || '0.5'}`} alt="" />
          <GridListTileBar
            className={classes.tileBar}
            title={item.name}
            subtitle={
              <span>


                            </span>
            }
          />
        </GridListTile>
      ))}

      </MuiGridList>
    </DialogContent>
      <DialogActions>

        <Pagination page={page}  size={'small'} count={Math.ceil(total / 30)} onChange={handleChangePage}/>
        <Button disabled={!currentRecord} variant="contained" color="primary" onClick={handleChoose}>
          Выбрать
        </Button>
      </DialogActions>
      {isDragActive && <div className={classes.dropZone}><div className={classes.dropZoneWrapper}>
          <div className={classes.dropZoneText}>Перетащите файлы сюда</div></div></div>}

      {isUploading &&  <div className={classes.uploading}>
          <CircularProgressWithLabel value={progress} />
              <Button variant="contained" className={classes.btnCancel} color="primary" onClick={handleUploadCancel} >
                  Отменить
              </Button>
      </div>}
    </div>
)
}


ModalMediaView.defaultProps = {

};

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="inherit">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default withWidth()(ModalMedia);
