import LabelIcon from '@material-ui/icons/Label';
import TagCategoryList from './TagCategoryList'
import TagCategoryEdit from './TagCategoryEdit'
import TagCategoryCreate from './TagCategoryCreate'

export default {
  create: TagCategoryCreate,
  edit: TagCategoryEdit,
  list: TagCategoryList,
  icon: LabelIcon,
};