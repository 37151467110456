import * as React from 'react';
import { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import {Button} from 'react-admin'

interface LinkFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
  value?: string
  type: string
}
const LinkSite: FC<LinkFieldProps> = ({
                                           basePath = '',
  value,
                                        type,
                                           record,
                                           source,
                                                         ...rest
                                         }) => (
  <a
    href={`http://yalta-go.dev.glob-com.ru/${type}/${record.slug || record.id}`}
    target={'_blank'}
  >
    На сайт
  </a>
);



export default LinkSite
