import React, {useEffect} from 'react'
import {Admin, Resource, DataProvider} from 'react-admin'

import LoginPage from "./resources/login";
import {AuthProvider} from "src/common/providers/AuthProvider";
import {dataProvider} from "src/common/providers/dataProvider";
// @ts-ignore

import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import {theme} from 'src/style'

import administrators from 'src/resources/administrators'
import user from 'src/resources/user'
import profile from 'src/resources/profile'
import article from 'src/resources/article'
import restaurant from 'src/resources/restaurant'
import articleCategory from 'src/resources/article-category'
import tag from 'src/resources/tag'
import tagCategory from 'src/resources/tag-category'
import push from 'src/resources/push'
import page from 'src/resources/page'
import feedback from 'src/resources/feedback'
import reservation from 'src/resources/reservation'
import like from 'src/resources/like'
import event from 'src/resources/event'
import entertainment from 'src/resources/entertainment'
import asset from 'src/resources/assets'
import property from 'src/resources/property/property'
import hotel from 'src/resources/property/hotel'
import boat from 'src/resources/property/boat'
import banner from 'src/resources/banner'
import city from 'src/resources/city'
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
  return (
    <Admin
      theme={theme}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      loginPage={LoginPage}
    >

      {permissions => {
        return [



          ...(permissions === 'restaurantManager' ? [
            <Resource name="reservation" {...reservation} options={{label: 'Резервы'}}/>,
            <Resource name="user" />,
            <Resource name="restaurant" />,
            <Resource name="admin" />,
          ] : [


          ]),
          ...(permissions === 'admin' || permissions === 'super_admin' ? [
            <Resource name="article" {...article} options={{label: 'Статьи'}}/>,
            <Resource name="article-category" {...articleCategory} options={{label: 'Категории статей'}}/>,
            <Resource name="restaurant" {...restaurant} options={{label: 'Рестораны'}}/>,
            <Resource name="event" {...event} options={{label: 'События'}}/>,
            <Resource name="entertainment" {...entertainment} options={{label: 'Экскурсии'}}/>,
            <Resource name="property" {...property} options={{label: 'Недвижимость'}}/>,
            <Resource name="hotel" {...hotel} options={{label: 'Гостиницы'}}/>,
            <Resource name="boat" {...boat} options={{label: 'Лодки'}}/>,
            <Resource name="banner" {...banner} options={{label: 'Баннеры'}}/>,

            <Resource name="tag" {...tag} options={{label: 'Теги'}}/>,
            <Resource name="tag-category" {...tagCategory} options={{label: 'Категории тегов'}}/>,
            <Resource name="page" {...page} options={{label: 'Текстовые страницы'}}/>,
            <Resource name="city" {...city} options={{label: 'Города'}}/>,
            <Resource name="asset" {...asset} options={{label: 'Медиа'}}/>,

            <Resource name="notification" {...push} options={{label: 'Уведомления'}}/>,
            <Resource name="feedback" {...feedback} options={{label: 'Отзывы'}}/>,
            <Resource name="reservation" {...reservation} options={{label: 'Резервы'}}/>,
            <Resource name="like" {...like} options={{label: 'Лайки'}}/>,
            <Resource name="restaurant-menu-item" />,
            <Resource name="restaurant-news" />,
            <Resource name="hotel-news" />,
            <Resource name="related-restaurants" />,

            <Resource name="event-part" />,
              <Resource name="user" {...user} options={{label: 'Пользователи'}}/>,
              <Resource name="profile" {...profile} options={{label: 'Профили'}}/>,

              <Resource name="admin" {...administrators} options={{label: 'Админы'}}/>,

          ] : [


          ])
        ];
      }
        }




        </Admin>
        )
      }

export default App
