import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import LinkField from 'src/components/fields/LinkField'
import {BannerTypeList, BannerViewTypeList} from 'src/types'
import LinkSite from 'src/components/fields/LinkSite'
import CustomChipField from 'src/components/fields/CustomChipField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'published': 'Опубликовано',
      'name': 'Название',
      'type': 'Тип',
      'viewType': 'Вид',
      'totalViews': 'Просмотры',
      'totalClicks': 'Клики',
      'link': 'Ссылка',
      'publishedAt': 'Публикация',
      'adminUpdatedAt': 'Обновлено',
      'admin': 'Админ'

    },
    data:  posts.map(item => ({
      ...item,
      admin: item.admin?.name || '',
      type: BannerTypeList.find(i => i.id === item.type)?.name || '',
      viewType: BannerViewTypeList.find(i => i.id === item.viewType)?.name || '',
      published: item.published ? 'Да' : 'Нет',
      createdAt: formatCsvDate(item.createdAt),
      adminUpdatedAt: formatCsvDate(item.adminUpdatedAt),
      publishedAt: formatCsvDate(item.publishedAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Banner'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <ReferenceInput
      label={'Рестораны'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="restaurantId" reference="restaurant"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceInput>
    <ReferenceInput
      label={'Событиe'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="eventId" reference="event"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceInput>
    <ReferenceInput
      label={'Экскурсия'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="entertainmentId" reference="entertainment"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceInput>
    <ReferenceInput
      label={'Недвижимость'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="propertyId" reference="property"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceInput>
    <ReferenceInput
      label={'Гостиница'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="propertyId" reference="hotel"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceInput>
    <ReferenceInput
      label={'Лодка'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="propertyId" reference="boat"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceInput>
    <SelectInput source="type" label={'Тип'} choices={BannerTypeList} variant={'outlined'}  resettable={true}/>
    <SelectInput source="viewType" label={'Вид'} choices={BannerViewTypeList} variant={'outlined'}  resettable={true}/>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>

    <TextInput source="totalViews||$lte" label={'Просмотров меньше'} variant={'outlined'}/>
    <TextInput source="totalViews||$gte" label={'Просмотров больше'} variant={'outlined'}/>
    <TextInput source="totalClicks||$lte" label={'Кликов меньше'} variant={'outlined'}/>
    <TextInput source="totalClicks||$gte" label={'Кликов больше'} variant={'outlined'}/>
    <ReferenceInput
      allowEmpty={false}
      label="Админ" source="adminId" reference="admin" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{field: 'name', order: 'ASC'}}
      fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name}`}/>
    </ReferenceInput>
  </Filter>
)

const BannerList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Баннеры"
      empty={<EmptyList title={'Нет баннеров'} description={'Вы можете добавить баннер'} buttonText={'Добавить баннер'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source="published" label={'П'}/>
      <TextField source="name" label={'Название'}/>
      <SelectField source="type" label={'Тип'} choices={BannerTypeList}/>
      <SelectField source="viewType" label={'Вид'} choices={BannerViewTypeList}/>
      <TextField source="totalViews" label={'Просмотры'}/>
      <TextField source="totalClicks" label={'Клики'}/>
      <ReferenceField label="Ресторан" source="restaurantId" reference="restaurant">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Событие" source="eventId" reference="event">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Экскурсия" source="entertainmentId" reference="entertainment">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Недв" source="propertyId" reference="property">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Гост" source="propertyId" reference="hotel">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Лодк" source="propertyId" reference="boat">
        <TextField source="name"/>
      </ReferenceField>
      <DateField source="publishedAt" label={'Время публикации'} showTime={true} />
      <DateField source="adminUpdatedAt" label={'Обновлено'} showTime={true} />
      <ReferenceField label="Админ" source="adminId" reference="admin">
        <TextField source="name"/>
      </ReferenceField>
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default BannerList;
