import React, {useState} from 'react';
import {
  BooleanInput,
  required,NumberInput,
  TextInput, useDataProvider, useRefresh, FormDataConsumer,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput, FormTab, SimpleForm
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import {MediaInput} from 'src/components/inputs/MediaInput'
import DateTimeInput from 'src/components/inputs/DateTimeInput'

export const EventPartForm = (props) => {
  const { record, mainRecord} = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    if (record?.id) {
      await dataProvider.update('event-part', {
        id: record.id,
        data: {
          eventId: mainRecord.id,
          ...data
        },
        previousData: record
      });
    } else {
      await dataProvider.create('event-part', {
        data: {
          eventId: mainRecord.id,
          ...data
        }
      });
    }

    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={'Добавить Время'}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <DateTimeInput
      label="Время"
      source="startTime"
      variant={'outlined'}
      helperText={'Для выбора даты нажмите на иконку календаря'}
      fullWidth={true}
    />
    <TextInput source={'place'}  label={'Что будет происходить?'} variant={'outlined'} fullWidth={true}/>
    <NumberInput source={'minPrice'}  label={'Мин. Цена'} variant={'outlined'} fullWidth={true} />
  </ModalForm>
}
