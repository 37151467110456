import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput,
  DateField,
  SimpleForm,
  TextField,
  SelectField,
  ReferenceField,
  ReferenceInput,
  required,
  AutocompleteInput, FormTab
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import AssetField from 'src/components/fields/AssetField'
import {FeedbackStatusList} from 'src/types'
import UserField from 'src/components/fields/UserField'
import {formatUserName} from 'src/utils/formatters'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'name': 'Название',
      'createdAt': 'Создано',
    },
    data:  posts.map(item => ({
      ...item,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Feedback'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <ReferenceInput  label="Ресторан" source="restaurantId" reference="restaurant"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <ReferenceInput  label="Событие" source="eventId" reference="event"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <ReferenceInput  label="Экускурсия" source="entertainmentId" reference="entertainment"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <ReferenceInput  label="Пользователь" source="userId" reference="user"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'phone||$contL': searchText} : {})})}
                     sort={{field: 'phone', order: 'ASC'}}
                     allowEmpty={false}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={formatUserName} />
    </ReferenceInput>
  </Filter>
)

const FeedbackList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Отзывы"
      empty={<EmptyList title={'Нет отзывов'} description={'Вы можете добавить Отзыв'} buttonText={'Добавить отзыв'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <SelectField source={'status'} label={'Статус'} choices={FeedbackStatusList}/>
      <ReferenceField label="Ресторан" source="restaurantId" reference="restaurant">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Событие" source="eventId" reference="event">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Экскурсия" source="entertainmentId" reference="restaurant">
        <TextField source="name"/>
      </ReferenceField>
      <TextField source={'mark'} label={'Оценка'} />
      <ReferenceField label="Пользователь" source="userId" reference="user">
        <UserField source="name"/>
      </ReferenceField>


      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default FeedbackList;
