import React, {
  FunctionComponent, useState,
} from 'react'
import PropTypes from 'prop-types'
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useInput, useTranslate, InputProps } from 'ra-core'
import {Button} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: '15px'
    },

  }),
  { name: 'RaFileInput' },
)

export interface FocalInputProps {
  onSetup?: (value, onChange) => void
}

export interface FocalPointInputOptions {

}

export const FocalPointInput: FunctionComponent<
  FocalInputProps & InputProps<FocalPointInputOptions>
  > = props => {
  const {
    children,
    parse,
    placeholder,
    resource,
    source,
    validate,
    onSetup,
    ...rest
  } = props


  const classes = useStyles(props)
  const {
    input: { onChange, value, ...inputProps },
    meta,
  } = useInput(
    {
    source,
    validate,
    ...rest,
  })


  console.log("NewValue", value);

  const handleSetup = (record) => {
    onSetup(value, onChange);
  };
  const handleDelete = () => {
    onChange(null);
  }


  return (
    <div
      className={classnames(classes.root)}
    >
      <Typography color={'textSecondary'} variant={'body2'}>
        {props.label}
      </Typography>
      <Box display={'flex'} p={0}  alignItems={'center'}>
      <ControlCameraIcon fontSize={'small'}/>
      <Typography color={'textPrimary'} variant={'body2'}>
        {value ? `(${value.x}, ${value.y})` : 'Не установлено' }
      </Typography>
      </Box>
      <Box display={'flex'} p={0} alignItems={'center'} mt={1}>

      <Button variant="outlined" size="small" color="primary" onClick={handleSetup}>
        Установить Центр
      </Button>
        {value && <Button variant="outlined" size="small" color="secondary" onClick={handleDelete}>
        Удалить
      </Button>}
      </Box>

      </div>
  )
}

FocalPointInput.defaultProps = {

}
