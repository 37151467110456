import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm} from 'react-final-form'

import {
  BooleanInput,
  TabbedForm,
  Datagrid,
  ReferenceInput,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  AutocompleteInput,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';
import {slug} from 'src/utils/formatters'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {ArticleEntityListTypeList, ArticleType} from 'src/types'

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}
const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (
    <FormTab
      label="Информация"
      path={'benefit'}
      {...props}>
      <ReferenceInput  label="Категория" source="tagCategoryId" reference="tag-category"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       allowEmpty={false}
                       validate={required()}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={(record) => `${record?.name}`} />
      </ReferenceInput>

      <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth/>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.tagCategoryId === 5 && (
          <TextInput source={'placeholderName'} label={'Название места на сайте'} helperText={''} variant={'outlined'}  fullWidth/>
        )}
      </FormDataConsumer>

      <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth/>
      <TextInput source={'description'} multiline={true} label={'Краткое описание'} variant={'outlined'} fullWidth={true}/>
      <MediaInput source={'image'} label={'Картинка'} />

    </FormTab>
  )
}

export const TagForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      <SeoTab/>
    </TabbedForm>
  );
};
