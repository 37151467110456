import * as React from 'react';
import {
    SimpleForm,
    SelectInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    SelectField,
    required,
    FormTab,
    BooleanInput,
    FormDataConsumer,
    ReferenceArrayInput, AutocompleteArrayInput, NumberInput, TabbedForm,
} from 'react-admin';
import {
    EntityStatus,
    EntityStatusList,
    LegalTypeList, PrepaymentType,
    PrepaymentTypeList, ProfileRole,
    ProfileRoleList,
    ProfileStatusList
} from "src/types";
import {useForm, useFormState} from "react-final-form";
import {useEffect} from "react";
import {slug} from "src/utils/formatters";
import TagCreateModal from "src/resources/tag/TagCreateModal";
import {MediaInput} from "src/components/inputs/MediaInput";
import {RichTextInput} from "src/components/inputs/RichTextInput";
import CityCreateModal from "src/resources/city/CityCreateModal";

const InfoTab = props => {

    return (<FormTab
        label="Информация"
        path={'benefit'}
        {...props}>
        <SelectInput source={'role'} label={'Роль'} choices={ProfileRoleList} variant={'outlined'}
                     disabled={props.isEdit} validate={required()} fullWidth/>
        <SelectInput source={'status'} label={'Статус'} choices={ProfileStatusList} variant={'outlined'}
                     validate={required()} fullWidth/>
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({formData, ...rest}) => formData.role !== ProfileRole.User && (
                <TextInput source={'name'} label={'Ник'} variant={'outlined'} fullWidth/>
            )}
        </FormDataConsumer>
        <TextInput source={'firstName'} label={'Имя'} variant={'outlined'} fullWidth/>
        <TextInput source={'lastName'} label={'Фамилия'} variant={'outlined'} fullWidth/>
        <BooleanInput source="autoApproveEnabled" label={'Разрешено моментальное бронирование'} variant={'outlined'}/>

        <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({formData, ...rest}) => formData.role !== ProfileRole.User && (
                <SelectInput source={'prepaymentType'} label={'Вид предоплаты'} choices={PrepaymentTypeList}
                             variant={'outlined'} fullWidth/>
            )}
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({
                  formData,
                  ...rest
              }) => formData.role !== ProfileRole.User && ![PrepaymentType.Full].includes(formData.prepaymentType) && (
                <TextInput source={'prepaymentMinPercent'} label={'Минимальный процент предоплаты'}
                           variant={'outlined'} fullWidth/>
            )}
        </FormDataConsumer>

    </FormTab>)
}

const LegalDetailsTab = props => {

    return (<FormTab
        label="Юр. данные"
        path={'benefit'}
        {...props}>
        <SelectInput source={'legalDetails.legalType'} label={'Тип Формы собственности'} choices={LegalTypeList}
                     variant={'outlined'}
                     disabled={props.isEdit} validate={required()}/>
        <SelectInput source={'status'} label={'Статус'} choices={ProfileStatusList} variant={'outlined'} fullWidth
                     validate={required()}/>

        <TextInput source={'legalDetails.firstName'} label={'Имя'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.lastName'} label={'Фамилия'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.middleName'} label={'Фамилия'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.companyName'} label={'Наименование организации'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.inn'} label={'ИНН'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.kpp'} label={'КПП'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.address'} label={'Фактический адрес'} variant={'outlined'} fullWidth/>
        <TextInput source={'legalDetails.legalAddress'} label={'Юридический адрес'} variant={'outlined'} fullWidth/>
        <MediaInput source={'legalDetails.innScan'} label={'Скан ИНН'} />
        <MediaInput source={'legalDetails.ogrnScan'} label={'Скан ОГРН'}/>
        <MediaInput source={'legalDetails.ustavScan'} label={'Скан устава'}/>
        <MediaInput source={'legalDetails.directorProtocolScan'} label={'Скан Протокола директора'}/>


    </FormTab>)
}
export const ProfileForm = props => {
    return (
        <TabbedForm {...props} redirect={'list'}>
            <InfoTab/>
            {props.isEdit && <LegalDetailsTab/>}

        </TabbedForm>
    );
};