import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Show,
  TabbedShowLayout,
  SimpleForm,
  DateField,
  Tab,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, SelectField, FunctionField,
} from 'react-admin';

import {formatPhone} from 'src/utils/formatters'

import LinkField from 'src/components/fields/LinkField'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Пользователь #{record.id} {record.defaultProfile?.name}</span>

const UserShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'phone'} label={'Телефон'}/>
        <FunctionField source="phone" label={'Телефон'} render={(item) => formatPhone(item.phone)}/>

        <FunctionField source={'lastName'} render={i => `${i.lastName || ''}${` ${i.firstName}` || ''}`}/>
        <LinkField value={'Заказы'} label={'Заказы'} link={'/user-action??displayedFilters={"userId":true}&filter={"userId": $id}'}/>
        <DateField source="createdAt" label={'Регистрация'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </Tab>

    </TabbedShowLayout>
  </Show>)
};

export default UserShow;