import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import {formatPhone} from 'src/utils/formatters'

const UserField = ({ source, sourceName, record }) => {
  const name = `${record.lastName || ''}${` ${record.firstName || ''}` || ''}`;
  const phone = record.phone
  return (<>
    {name && <Typography variant={'body2'}>{name}</Typography>}
    {phone && <Typography variant={'body2'}>{formatPhone(phone)}</Typography>}
    </>)
}
UserField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  sourceName: PropTypes.string,
}
UserField.defaultProps = {
  sourceName: 'name'
};

export default UserField
