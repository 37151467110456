import * as React from 'react';
import { FC } from 'react';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import {
  linkToRecord,
  NumberField,
  useListContext,
  DatagridProps,
  Identifier,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import {getMediaPath} from 'src/utils/media'

const useStyles = makeStyles(theme => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 3;
  if (width === 'lg') return 5;
  return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList: FC<GridProps & { nbItems?: number }> = ({
                                                                 width,
                                                                 nbItems = 20,
                                                               }) => {
  const classes = useStyles();
  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width as Breakpoint)}
      className={classes.gridList}
    >
      {' '}
      {times(nbItems, key => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};
const ListItem = ({record, basePath, ...rest}) => {
  const classes = useStyles();
  const whiteListsFacets = ['color'];
  const whiteListsTags = ['Корзина'];

  const tags = (() => {
    console.log("CalcTags", record.tags)
    return[];
    return record.tags.filter(val => whiteListsTags.includes(val.value)).map(val => val.value);
  })()

  return ( <GridListTile
    {...rest}
   // @ts-ignore
   component={Link}
   key={record.id}
   to={linkToRecord(basePath, record.id)}
 >
   <img src={`${getMediaPath(record.source)}?preset=medium&fpx=${record.focalPoint?.x || '0.5'}&fpy=${record.focalPoint?.y || '0.5'}`} alt={record.name} />
   <GridListTileBar
     className={classes.tileBar}
     title={record.name}
     subtitle={
        <div>
       <span>{record.authorName}</span>
        </div>
     }
   />
 </GridListTile>);
}
const LoadedGridList: FC<GridProps> = ({ width, onSelect }) => {
  const { ids, data, basePath } = useListContext();
  const classes = useStyles();

  if (!ids || !data) return null;
  const getFacetsColor = () => {

  }
  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width as Breakpoint)}
      className={classes.gridList}
    >
      {ids.map((id: Identifier) => (
        <ListItem record={data[id]} basePath={basePath}/>
      ))}
    </MuiGridList>
  );
};

interface GridProps {
  onSelect?: (record) => void
  width: Breakpoint
}

const GridList: FC<WithWidth & GridProps> = ({ width,onSelect }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList width={width} onSelect={onSelect}/>
  ) : (
    <LoadingGridList width={width} onSelect={onSelect}/>
  );
};

export default withWidth()(GridList);