import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import {format, parse} from 'date-fns'
const moment = require('moment');
const useStyles = makeStyles(
  {
    timePicker: {
      display: 'flex',
      height: '100%',
      width: '100%',
      '& .rc-time-picker-clear': {
        display: 'none'
      },
    },
    popup: {
      zIndex: 9999999,
      paddingTop: '9px',
      position: 'relative',
    },
    input: {
      height: '100%',
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
      borderRadius: '4px',
      borderWidth: '1px',
      padding: '10.5px 14px !important',
      background: 'transparent',
      lineHeight: '1.1876em !important',
      fontSize: '1rem !important',
      color: 'rgba(0, 0, 0, 0.87) !important'
    },
    label: {
      margin: '0px'
    }
  },
  {name: 'DayTypeInputItem'}
);

const DayTypeInputItem = props => {
  const {
    classes: classesOverride,
    id,
    choice,
    onChange,
    name,
    translateChoice,
    value,
    ...rest
  } = props;
  const classes = useStyles(props);

  if(value) {
    console.log("TimeValue", value, parse(value, 'HH:mm', new Date()));
  }
  return (
    <FormControlLabel
      htmlFor={`${name}`}
      key={name}
      onChange={onChange}
      className={classes.label}
      label={''}
      control={
        <TimePicker
          showSecond={false}
          className={classes.timePicker}
          popupClassName={classNames(classes.popup)}
          inputClassName={classNames(classes.input)}
          placeholder={props.placeholder ?? props.label}
          inputReadOnly
          value={value ? moment(parse(value,'HH:mm', new Date())) : null}
          onChange={(date) => {
            if (date) {
              onChange(format(date.toDate(), 'HH:mm'))
            } else {
              onChange(null)
            }
          }}
        />
      }
    />
  );
};

export default DayTypeInputItem;
