import * as React from 'react';
import { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import {Button} from 'react-admin'

interface LinkFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
  link:  any
  value?: string
}
const LinkField: FC<LinkFieldProps> = ({
                                           basePath = '',
link,
  value,
                                           record,
                                           source,
                                                         ...rest
                                         }) => (
  <Link
    to={encodeURI((typeof link === 'function' ? link(record) : link)
      .replaceAll('$id', record['id'] as string)

    )}
  >
    {value || record[source]}
  </Link>
);



export default LinkField
