
import DescriptionIcon from '@material-ui/icons/Description';
import ArticleList from 'src/resources/article/ArticleList'
import ArticleEdit from 'src/resources/article/ArticleEdit'
import ArticleCreate from 'src/resources/article/ArticleCreate'

export default {
  create: ArticleCreate,
  edit: ArticleEdit,
  list: ArticleList,
  icon: DescriptionIcon,
};