import * as React from 'react';
import {FC, useRef, useState} from 'react';
import keyBy from 'lodash/keyBy'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, ReferenceArrayInput, AutocompleteArrayInput,
} from 'react-admin';
import {AssetEditForm} from 'src/resources/assets/AssetForm'

interface ProductTitleProps {
  record?: any;
}

const ProductTitle: FC<ProductTitleProps> = ({record}) => <span>Файл {record.name}</span>

const AssetEdit: FC<EditProps> = props => {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<ProductTitle/>}>
      <SimpleForm initialValues={{preview: 'medium'}} >
      <AssetEditForm />
      </SimpleForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AssetEdit;