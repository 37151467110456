import * as React from 'react';
import {FC, useEffect, useRef} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  SelectInput,
  FunctionField,
  Datagrid,
  BooleanInput,
  DateField,
  SimpleForm,
  TextField,
  SelectField,
  ReferenceField,
  ReferenceInput,
  required,
  AutocompleteInput, FormTab, useRefresh, ReferenceArrayInput
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import AssetField from 'src/components/fields/AssetField'
import {ReservationStatusList, ReservationTypeList} from 'src/types'
import UserField from 'src/components/fields/UserField'
import {formatPhone, formatUserName} from 'src/utils/formatters'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'restaurantName': 'Ресторан',
      'name': 'Имя',
      'phone': 'Телефон',
      'reservationType': 'Тип',
      'reservationTime': 'Время',
      'reservationDuration': 'Длительность минут',
      'numOfPersons': 'Кол-во гостей',
      'status': 'Статус',
      'comment': 'Комментарий клиента',
      'commentManager': 'Комментарий менеджера',
      'reservationComment': 'Комментарий менеджера о клиенте',
      'createdAt': 'Создано',
      'updatedAt': 'Обновлено',
    },
    data:  posts.map(item => ({
      ...item,
      restaurantName: item?.restaurant?.name || '',
      reservationType: formatListValue(item.type, ReservationTypeList),
      status: formatListValue(item.type, ReservationStatusList),
      type: formatListValue(item.type, ReservationTypeList),
      createdAt:  formatCsvDate(item.createdAt),
      reservationTime:  formatCsvDate(item.reservationTime),
      reservationDuration:  formatCsvDate(item.reservationDuration),
      manager: item.manager?.name,
      reservationComment: item.user?.reservationComment,
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Reservation'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <ReferenceInput  label="Ресторан" source="restaurantId" reference="restaurant"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <DateField source="reservationTime" label={'Время'} showTime={true} />
    <TextInput source="phone||$contL" label={'Телефон'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <TextInput source="name||$contL" label={'Имя'}  variant={'outlined'}  resettable={true}/>
    <SelectInput source="reservationType" label={'Тип'}  variant={'outlined'} choices={ReservationTypeList}  resettable={true}/>
    <SelectInput source="status" label={'Статус'}  variant={'outlined'} choices={ReservationStatusList} resettable={true}/>
    <ReferenceInput  label="Менеджер" source="managerId" reference="admin"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     filter={{role: 'restaurantManager'}}
                     allowEmpty={false}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    <DateField source="createdAt" label={'Создано'} showTime={true} />
  </Filter>
)

const ReservationDataGrid = props => {
  const refresh = useRefresh();
  const refreshRef = useRef(null);
  useEffect(() => {
    if(props.page !== 1){
      return;
    }
    refreshRef.current = setInterval(() => refresh(), 20 * 1000);
    return () => {
      if( refreshRef.current){
        clearInterval( refreshRef.current)
      }
    }
  }, [props.page])
  return (<Datagrid {...props} rowClick={'edit'}>
    <IdShowButtonField source={'id'} label={'ID'}/>
    <SelectField source={'status'} label={'Статус'} choices={ReservationStatusList}/>
    <SelectField source={'reservationType'} label={'Тип'} choices={ReservationTypeList}/>
    <TextField source={'name'} label={'Имя'}/>
    <FunctionField source={'phone'} label={'Телефон'} render={i => formatPhone(i.phone)} />
    <DateField source="reservationTime" label={'Время резерва'} showTime={true} />
    <ReferenceField label="Ресторан" source="restaurantId" reference="restaurant">
      <TextField source="name"/>
    </ReferenceField>
    <ReferenceField label="Менеджер" source="managerId" reference="admin">
      <TextField source="name"/>
    </ReferenceField>
    <DateField source="createdAt" label={'Создано'} showTime={true} />
    <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
  </Datagrid>)
}
const ReservationList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      sort={{field: 'id', order: 'DESC'}}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Резервы"
      empty={<EmptyList title={'Нет резервов'} description={'Вы можете добавить резерв'} buttonText={'Добавить Резерв'}/>}
    >
      <ReservationDataGrid/>
    </List>
  );
}

export default ReservationList;
