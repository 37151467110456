import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {useForm, useFormState} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import {MediaInput} from 'src/components/inputs/MediaInput'
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import {EventPartList} from 'src/resources/event/event-parts/EventPartList'
import TagCreateModal from 'src/resources/tag/TagCreateModal'
import {ArticleEntityListType, ArticleType, EntityStatusList, EntityStatus} from 'src/types';
import CityCreateModal from 'src/resources/city/CityCreateModal'

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}
const GalleryTab = props => {
  return (<FormTab
    label="Галерея"
    path={'gallery'}
    {...props}>
    <MediaInput source={'gallery'} label={'Галерея'}  isMulti/>
  </FormTab>)
}

const EventPartListTab = props => {
  return (<FormTab
    label="Раписание"
    path={'schedule'}
    {...props}>
    <EventPartList/>
  </FormTab>)
}

const InfoTab = props => {
  const form = useForm();
  const { values } = useFormState();
  const {record} = props;
  useEffect(() => {
    if(!record){
      return;
    }
    if (record.tags && !values.tagsIds) {
      form.change('tagsIds', record?.tags?.map(i => i.id));
      form.change('tags', []);
    }

    if (record.spPlacementTags && !values.spPlacementTagsIds) {
      form.change('spPlacementTagsIds', record?.spPlacementTags?.map(i => i.id));
      form.change('spPlacementTags', []);
    }




  }, [record])
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'}  defaultValue={true} variant={'outlined'}/>
    <SelectInput
        source="status"
        label={'Статус'}
        choices={EntityStatusList}
        variant={'outlined'} 
      />

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.status === EntityStatus.Rejected && (
        <TextInput source={'moderatorComment'} label={'Комментарий модератора'}  variant={'outlined'} validate={required()} fullWidth={true}/>
      )}
    </FormDataConsumer>
    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <ReferenceInput
      allowEmpty={false}
      label="Категория (главный тег)" source="eventTypeTagId" reference="tag" variant={'outlined'}
      filter={{tagCategoryId: 12}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceInput>
    <MediaInput source={'image'} label={'Картинка Обложка карточки'} validate={required()} />
    <MediaInput source={'imageLarge'} label={'Картинка Большая'} />
    <ReferenceArrayInput
      label={'Теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
    <BooleanInput source="isFree" label={'Бесплатное событие'}  defaultValue={true} variant={'outlined'}/>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => !formData.isFree && (

        <NumberInput source={'minPrice'} label={'Мин. цена'} variant={'outlined'} fullWidth={true}/>

      )}
    </FormDataConsumer>
    <TextInput source={'description'} multiline={true} label={'Краткое описание'} helperText={'Показывается на большом слайдере на странице афиши'} variant={'outlined'} fullWidth={true}/>
    <RichTextInput source={'about'} multiline={true} label={'О Событии'} variant={'outlined'} fullWidth={true}/>
    <ReferenceInput
      allowEmpty={false}
      label="Город" source="cityId" reference="city" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<CityCreateModal/>} createLabel={'Создать город'} createItemLabel={'Создать город'} />
    </ReferenceInput>
    <TextInput source="address" label={'Адрес'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="location" label={'Координаты'} helperText={'Через запуятую, например 55.616351,36.431444'}
               parse={value => {
                 const parts = value.split(',');
                 if(parts.length < 2 || !parts[0] || !parts[1]){
                   return value;
                 }
                 return {lat: parts[0].trim(), lng: parts[1].trim()}
               }}
               format={value => {
                 if(value?.lat && value.lng){
                   return `${value.lat},${value.lng}`
                 }
                 return value;
               }}
               multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="site" label={'Сайт'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="instagramLink" label={'Instagram'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="facebookLink" label={'Facebook'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="vkontakteLink" label={'VK'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source={'mediaSource'} label={'Источник фотографий'}  variant={'outlined'}  fullWidth={true}/>

    <ReferenceArrayInput
      label={'Теги спецразмещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''} create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
  </FormTab>)
}

export const EventForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      {props.isEdit && <EventPartListTab/>}
      <GalleryTab/>
      <SeoTab/>
    </TabbedForm>
  );
};
