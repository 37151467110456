import { ReduxState } from 'ra-core'
export interface IRequestData {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
  data?: any
  token?: string
  host?: string
}

export interface IResponse {
  data: any
  err: any
}

export interface BaseAction {
  type: string
  payload: any
}

export type ThemeName = 'light' | 'dark'

export interface AppState extends ReduxState {

}
export const AdminRoles = [
  {id: 'admin', name: 'Администратор'},
  {id: 'restaurantManager', name: 'Ресторан менеджер'},
  {id: 'manager', name: 'Контент менеджер'}
]
export interface IUser {
  id: number
  name: string
}

export enum CountryCode {
  Ru = 'ru'
}


export const PushTypes = [
  { id: 'admin', name: 'Общее уведомление' },
  { id: 'userRegistered', name: 'Пользователь зарегистрирован' }
]
export const PushNotificationChannel = [
  { id: 'push', name: 'Push' },
  { id: 'email', name: 'Email' },
  { id: 'alert', name: 'Уведомление' }
]
export const PushTargetTypes = [
  { id: 'all', name: 'Всем' },
  { id: 'user', name: 'Пользователю' }
]
export const PushStatuses = [
  { id: 'created', name: 'Черновик' },
  { id: 'toSent', name: 'Отправить' },
]
export const PushPriorities = [
  { id: 'high', name: 'Высокий' },
  { id: 'normal', name: 'Обычный' },
]
export const PushSoundTypes = [
  { id: '', name: 'Отключен' },
  { id: 'default', name: 'Включен' },
]

export enum FeedbackStatus {
  Moderation = 'moderation',
  Approved = 'approved',
  Rejected = 'rejected',
}
export const FeedbackStatusList = [
  { id: FeedbackStatus.Moderation, name: 'Модерация' },
  { id: FeedbackStatus.Approved, name: 'Запрувлен' },
  { id: FeedbackStatus.Rejected, name: 'Отклонен' },
]

export const FeedbackMarkList = [
  { id: 0, name: '0' },
  { id: 1, name: '2' },
  { id: 2, name: '3' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
]



export enum ReservationType {
  Table = 'table',
  Banquet = 'banquet',
}

export const ReservationTypeList = [
  { id: ReservationType.Table, name: 'Стол' },
  { id: ReservationType.Banquet, name: 'Банкет' }
]


export enum ReservationStatus {
  New = 'new',
  InProgress = 'inProgress',
  Approved = 'approved',
  Rejected = 'rejected',
  ClientCanceled = 'clientCanceled',
  Finished = 'finished',
}

export const ReservationStatusList = [
  { id: ReservationStatus.New, name: 'Новый' },
  { id: ReservationStatus.InProgress, name: 'В работе' },
  { id: ReservationStatus.Approved, name: 'Одобрен' },
  { id: ReservationStatus.Rejected, name: 'Отклонен' },
  { id: ReservationStatus.ClientCanceled, name: 'Отменен клиентов' },
  { id: ReservationStatus.Finished, name: 'Посещение состоялось' },
]


export enum ArticleType {
  News = 'news',
  Article = 'article',
  EntityList = 'entityList',
}
export enum ArticleEntityListType {
  Events = 'events',
  Restaurants = 'restaurants',
  Entertainment = 'entertainments',
  Property = 'properties',
  Boat = 'boats',
  Hotel = 'hotels',
}

export const ArticleTypeList = [
  { id: ArticleType.News, name: 'Новость' },
  { id: ArticleType.Article, name: 'Статья' },
  { id: ArticleType.EntityList, name: 'Список' }
]
export const ArticleEntityListTypeList = [
  { id: ArticleEntityListType.Restaurants, name: 'Рестораны' },
  { id: ArticleEntityListType.Entertainment, name: 'Экскурсии' },
  { id: ArticleEntityListType.Events, name: 'События' },
  { id: ArticleEntityListType.Property, name: 'Недвижимость' },
  { id: ArticleEntityListType.Hotel, name: 'Гостиницы' },
  { id: ArticleEntityListType.Boat, name: 'Лодки' }
]

export const ArticleGroupTypeList = [
  { id: 'lifestyle', name: 'LifeStyle' },
  { id: 'business', name: 'Бизнес' },
  { id: 'news', name: 'Новости' }
]

export enum AverageBillType {
  Low = 1,
  Normal = 2,
  Expensive = 3,
}
export const AverageBillTypeList = [
  { id: AverageBillType.Low, name: '₽' },
  { id: AverageBillType.Normal, name: '₽₽' },
  { id: AverageBillType.Expensive, name: '₽₽₽' }
]

export enum BannerType {
  UnSkippable = 'unSkippable',
  Top = 'top',
}
export const BannerTypeList = [
  { id: BannerType.UnSkippable, name: 'Не скрываемый' },
  { id: BannerType.Top, name: 'Верхний' },
]
export enum BannerViewType {
  Custom = 'custom',
  Events = 'events',
  Restaurants = 'restaurants',
  Entertainment = 'entertainments',
  Property = 'properties',
  Boat = 'boats',
  Hotel = 'hotels',
}
export const BannerViewTypeList = [
  { id: BannerViewType.Custom, name: 'Кастомный' },
  { id: BannerViewType.Restaurants, name: 'Ресторан' },
  { id: BannerViewType.Entertainment, name: 'Экскурсия' },
  { id: BannerViewType.Events, name: 'Событие' },
  { id: BannerViewType.Property, name: 'Недвижимость' },
  { id: BannerViewType.Hotel, name: 'Гостиница' },
  { id: BannerViewType.Boat, name: 'Лодка' }
]

export enum PropertyType {
  Boat = 'boat',
  Transport = 'transport',
  Property = 'property',
  Hotel = 'hotel',
}


export enum RestaurantNewsType {
  News = 'news',
  Article = 'article'
}

export const RestaurantNewsTypeList = [
  { id: RestaurantNewsType.Article, name: 'Статья' },
  { id: RestaurantNewsType.News, name: 'Новость' },

]


export enum HotelNewsType {
  News = 'news',
  Article = 'article'
}

export const HotelNewsTypeList = [
  { id: HotelNewsType.Article, name: 'Статья' },
  { id: HotelNewsType.News, name: 'Новость' },
]
export enum EntityStatus {
  Draft = 'draft',
  Moderation = 'moderation',
  Rejected = 'rejected',
  Published = 'published',
  Unpublished = 'unpublished',
}
export const EntityStatusList = [
  { id: EntityStatus.Draft, name: 'Черновик' },
  { id: EntityStatus.Moderation, name: 'На модерации' },
  { id: EntityStatus.Rejected, name: 'Отклонено' },
  { id: EntityStatus.Published, name: 'Опубликовано' },
  { id: EntityStatus.Unpublished, name: 'Снять с публикации' },
]
export const ReservationDurationList = Array.from({length: 30}, (_, i) => i + 1).map(i => i * 30).map( i => ({id: i, name: `${i}`}))

export enum ProfileStatus {
  Draft = 'draft',
  Moderation = 'moderation',
  Moderating = 'moderating',
  Rejected = 'rejected',
  WaitDetails = 'waitDetails',
  Published = 'published',
  Blocked = 'blocked',
}
export const ProfileStatusList = [
  { id: ProfileStatus.Draft, name: 'Черновик' },
  { id: ProfileStatus.Moderation, name: 'На модерации' },
  { id: ProfileStatus.Moderating, name: 'Модерируется' },
  { id: ProfileStatus.Rejected, name: 'Отклонен' },
  { id: ProfileStatus.WaitDetails, name: 'Ожидание Юр. данных' },
  { id: ProfileStatus.Published, name: 'Одобрен' },
  { id: ProfileStatus.Blocked, name: 'Заблокирован' },
]


export enum ProfileRole {
  Entertainment = 'entertainment',
  Restaurant = 'restaurant',
  Boat = 'boat',
  Hotel = 'hotel',
  Property = 'property',
  Event = 'event',
  User = 'user',
}
export const ProfileRoleList = [
  { id: ProfileRole.Entertainment, name: 'Гид' },
  { id: ProfileRole.Restaurant, name: 'Ресторан' },
  { id: ProfileRole.Boat, name: 'Лодки' },
  { id: ProfileRole.Hotel, name: 'Отель' },
  { id: ProfileRole.Property, name: 'Недвижимость' },
  { id: ProfileRole.Event, name: 'События' },
  { id: ProfileRole.User, name: 'Пользователь' },
]


export enum LegalType {
  Self = 'self',
  Fiz = 'fiz',
  Ip = 'ip',
  LegalEntity = 'legalEntity',
}
export const LegalTypeList = [
  { id: LegalType.Self, name: 'Самозанятый' },
  { id: LegalType.Fiz, name: 'Физ. лицо' },
  { id: LegalType.Ip, name: 'ИП' },
  { id: LegalType.LegalEntity, name: 'Юр. лицо' },
]


export enum BookingStatus {
  New = 'new',
  Approved = 'approved',
  Rejected = 'rejected',
  Booked = 'booked',
  CanceledByClient = 'canceledByClient',
  CanceledByOwner = 'canceledByOwner',
  Completed = 'completed',
  CompleteApproved = 'completeApproved',
  CompleteRejected = 'completeRejected',
}

export const BookingStatusList = [
  { id: BookingStatus.New, name: 'Не подтвреждено' },
  { id: BookingStatus.Approved, name: 'Подтверждено' },
  { id: BookingStatus.Rejected, name: 'Отклонено' },
  { id: BookingStatus.Booked, name: 'Забронировано' },
  { id: BookingStatus.CanceledByClient, name: 'Отменено клиентом' },
  { id: BookingStatus.CanceledByOwner, name: 'Отменено организатором' },
  { id: BookingStatus.Completed, name: 'Заверешено' },
  { id: BookingStatus.CompleteApproved, name: 'Завершено и одобрено' },
  { id: BookingStatus.CompleteRejected, name: 'Заврешено и отклонено' },
]

export enum VisitStatus {
  None = 'none',
  Visited = 'visited',
  Missed = 'missed',
}
export const VisitStatusList = [
  { id: VisitStatus.None, name: 'Неизвестно' },
  { id: VisitStatus.Visited, name: 'Посетил' },
  { id: VisitStatus.Missed, name: 'Не пришел' }
]
export enum BookingPaymentStatus {
  NotPaid = 'notPaid',
  PrePaid = 'prePaid',
  Paid = 'paid',
  CancelRequest = 'cancelRequest',
  Cancelled = 'cancelled',
}
export const BookingPaymentStatusList = [
  { id: BookingPaymentStatus.NotPaid, name: 'Не оплачено' },
  { id: BookingPaymentStatus.PrePaid, name: 'Предоплачено' },
  { id: BookingPaymentStatus.Paid, name: 'Оплачено' },
  { id: BookingPaymentStatus.CancelRequest, name: 'Запрос на возврат' },
  { id: BookingPaymentStatus.Cancelled, name: 'Возвращено' },
]

export enum PrepaymentType {
  Full = 'full',
  Disabled = 'disabled',
  PostOnline = 'postOnline',
  PostCash = 'postCache',
}
export const PrepaymentTypeList = [
  { id: PrepaymentType.Full, name: 'Полная предоплата' },
  { id: PrepaymentType.Disabled, name: 'Полностью Наличными' },
  { id: PrepaymentType.PostOnline, name: 'Частичная онлайн' },
  { id: PrepaymentType.PostCash, name: 'Частичная наличными' },
]


export enum BookingType {
  Private = 'private',
  Group = 'group',
}
export const BookingTypeList = [
  { id: BookingType.Private, name: 'Индивдуальная' },
  { id: BookingType.Group, name: 'Групповая' },
]

export enum ScheduleType {
  ManualDays = 'manualDays',
  WeekDays = 'weekDays',
  AllDays = 'allDays',
}
export const ScheduleTypeList = [
  { id: ScheduleType.ManualDays, name: 'В определенные даты' },
  { id: ScheduleType.WeekDays, name: 'По Еженедельному расписанию' },
  { id: ScheduleType.AllDays, name: 'Ежедневно' },
]

export enum PriceType {
  PerPerson = 'perPerson',
  PerEntity = 'perEntity',
}
export const PriceTypeList = [
  { id: PriceType.PerPerson, name: 'За человека' },
  { id: PriceType.PerEntity, name: 'За экскурсию' }
]


export enum PriceKind {
  Single = 'single',
  ByTicket = 'byTicket',
}
export const PriceKindList = [
  { id: PriceKind.Single, name: 'Единая цена' },
  { id: PriceKind.ByTicket, name: 'По типам' }
]
