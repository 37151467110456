import * as React from 'react';
import {FC, useEffect, useState} from 'react';

import {useForm, useFormState} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import {MediaInput} from 'src/components/inputs/MediaInput'
import {formatUserName, slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import TagCreateModal from 'src/resources/tag/TagCreateModal'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import TimeInput from 'src/components/inputs/TimeInput'
import {AverageBillTypeList, BannerViewType, PropertyType, EntityStatusList, EntityStatus} from 'src/types';
import CityCreateModal from 'src/resources/city/CityCreateModal'
import {HotelNewsList} from 'src/resources/property/hotel/news/HotelNewsList'

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}
const AboutGalleryTab = props => {
  return (<FormTab
    label="Описание/Галерея"
    path={'contacts'}
    {...props}>
    <RichTextInput source="about" label={'Описание'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'aboutImage'} label={'Картинка описания'}/>
    <MediaInput source={'gallery'} label={'Галерея'}  isMulti/>
  </FormTab>)
}

const ChildrenTab = props => {
  return (<FormTab
    label="Детям"
    path={'children'}
    {...props}>
    <RichTextInput source="children" label={'Детям'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'childrenGallery'} label={'Галерея детям'} helperText={'Галерея для вывода в разделе кухня'}
                isMulti/>
  </FormTab>)
}
const BeautyAndHealthTab = props => {
  return (<FormTab
    label="Красота"
    path={'beautyAndHealth'}
    {...props}>
    <RichTextInput source="beautyAndHealth" label={'Красота и здоровье'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'beautyAndHealthGallery'} label={'Галерея Красота и здоровье'} helperText={'Галерея для вывода в разделе кухня'}
                isMulti/>
  </FormTab>)
}


const EntertainmentTab = props => {
  return (<FormTab
    label="Развлечения"
    path={'children'}
    {...props}>
    <RichTextInput source="entertainment" label={'Детям'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'entertainmentGallery'} label={'Галерея разлвечения'} helperText={'Галерея для вывода в разделе кухня'}
                isMulti/>
  </FormTab>)
}


const FeaturesTab = props => {
  const form = useForm();
  const {values} = useFormState();
  const {record} = props;
  useEffect(() => {

    if (!record) {
      return;
    }

    if (record.featuresTags && !values.featuresTagsIds) {
      form.change('featuresTagsIds', record?.featuresTags?.map(i => i.id));
      form.change('featuresTags', []);
    }

  }, [record])
  return (<FormTab
    label="Особенности"
    path={'features'}
    {...props}>
    <ReferenceArrayInput
      label={'Уточняющие теги'}
      filter={{tagCategoryId: 15}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="featuresTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'}
                              createItemLabel={'Создать тег'}/>

    </ReferenceArrayInput>
    <RichTextInput source="features" label={'Особенности'}
                   helperText={'Расскажите о фишках и особенностях гостиницы коротко'} multiline={true}
                   variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'featuresGallery'} label={'Галерея'} isMulti/>
  </FormTab>)
}

const HotelNewsListTab = props => {
  return (<FormTab
    label="Новости"
    path={'news'}
    {...props}>
    <HotelNewsList/>
  </FormTab>)
}
const InfoTab = props => {
  const form = useForm();
  const { values } = useFormState();
  const {record} = props;
  useEffect(() => {

    setTimeout( () => {
      if(!record){
        return;
      }
      form.change('owner', undefined);

      if (record.tags && !values.tagsIds) {
        form.change('tagsIds', record?.tags?.map(i => i.id));
        form.change('tags', []);
      }
      if (record.restaurants && !values.restaurantsIds) {
        form.change('restaurantsIds', record?.restaurants?.map(i => i.id));
        form.change('restaurants', []);
      }
      if (record.spPlacementTags && !values.spPlacementTagsIds) {
        form.change('spPlacementTagsIds', record?.spPlacementTags?.map(i => i.id));
        form.change('spPlacementTags', []);
      }
    }, 300);

  }, [record])
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} defaultValue={true} variant={'outlined'}/>
    <SelectInput
        source="status"
        label={'Статус'}
        choices={EntityStatusList}
        variant={'outlined'} 
      />

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.status === EntityStatus.Rejected && (
        <TextInput source={'moderatorComment'} label={'Комментарий модератора'}  variant={'outlined'} validate={required()} fullWidth={true}/>
      )}
    </FormDataConsumer>
    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'description'} multiline={true} label={'Краткое описание'} variant={'outlined'} fullWidth={true}/>

    <MediaInput source={'image'} label={'Картинка'} helperText={'Используется для вывода в карточках'} />
    <MediaInput source={'imageLarge'} label={'Картинка Большая'} helperText={'Фоновая картинка на странице экскурсии (1 экран)'} />
    <MediaInput source={'logo'} label={'Логотип'}
                helperText={'Используется для вывода на странице гостиницы, отзыва'}/>

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === PropertyType.Hotel && (
        <TextInput source={'siteLink'} label={'Ссылка на сайт/страницу'}  variant={'outlined'} validate={required()} fullWidth={true}/>
      )}
    </FormDataConsumer>
    <ReferenceArrayInput
      label={'Главные теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
    <SelectInput
      source="stars"
      label={'Кол-во звезд'}
      variant={'outlined'} fullWidth={true}
      choices={Array.from({length: 5}, (_, i) => i + 1).map(i => ({id: i, name: `${i}`}))}
    />
    <NumberInput source={'minPrice'} label={'Минимальная цена'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="priceText" label={'примечание к цене'} multiline={true} variant={'outlined'} fullWidth={true}/>

    <ReferenceInput
      allowEmpty={false}
      label="Категория (главный тег)" source="hotelTypeTagId" reference="tag" variant={'outlined'}
      filter={{tagCategoryId: 14}}
      validate={required()}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceInput>

    <ReferenceArrayInput
      label={'Рестораны'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="restaurantsIds" reference="restaurant"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={false}
      label="Город" source="cityId" reference="city" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<CityCreateModal/>} createLabel={'Создать город'} createItemLabel={'Создать город'} />
    </ReferenceInput>
    <TextInput source="address" label={'Адрес'} helperText={'Описание адрес или как добраться'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="location" label={'Координаты'} helperText={'Через запуятую, например 55.616351,36.431444'}
               parse={value => {
                 const parts = value.split(',');
                 if(parts.length < 2 || !parts[0] || !parts[1]){
                   return value;
                 }
                 return {lat: parts[0].trim(), lng: parts[1].trim()}
               }}
               format={value => {
                 if(value?.lat && value.lng){
                   return `${value.lat},${value.lng}`
                 }
                 return value;
               }}
               multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="feature" label={'Фишка (Для карточек)'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="site" label={'Сайт'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="instagramLink" label={'Instagram'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="facebookLink" label={'Facebook'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="vkontakteLink" label={'VK'} multiline={true} variant={'outlined'} fullWidth={true}/>

    <ReferenceArrayInput
      label={'Теги спецразмещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''} create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
  </FormTab>)
}

export const HotelForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      <AboutGalleryTab/>
      <FeaturesTab/>
      <BeautyAndHealthTab/>
      <EntertainmentTab/>
      <ChildrenTab/>
      {props.isEdit && <HotelNewsListTab/>}
      <SeoTab/>
    </TabbedForm>
  );
};
