import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
} from 'react-admin';
import {UserForm} from 'src/resources/user/UserForm'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новый пользователь</span>

const UserCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <UserForm/>
    </Create>
  );
};

export default UserCreate;