import * as React from 'react';
import { useCallback, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { FieldTitle, useInput, InputProps, ChoicesProps } from 'ra-core';
import DayTypeInputItem from 'src/components/inputs/WorkScheduleInput/DayTypeInputItem'
import { Box, Grid } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import DayTimeInputItem from 'src/components/inputs/WorkScheduleInput/DayTimeInputItem'
import {FileInputOptions, FileInputProps} from 'src/components/inputs/MediaInput'

const sanitizeRestProps = ({
                             setFilter,
                             setPagination,
                             setSort,
                             loaded,
                             ...rest
                           }: any) => (rest);

const useStyles = makeStyles(
  theme => ({
    root: {},
    table: {
      minWidth: 650,
    },
    label: {
      fontSize: '0.8rem',
      marginBottom: '10px'
    },
    timeSep: {
      padding: '0px 10px'
    },
    timeContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    timeInput: {
      maxWidth: '75px'
    }
  }),
  { name: 'RaUserNotificationsInput' }
);

const WorkScheduleInput = props => {
  const {
    choices = [],
    classes: classesOverride,
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    optionText,
    optionValue,
    options,
    parse,
    resource,
    row,
    values,
    source,
    translate,
    translateChoice,
    validate,
    ...rest
  } = props;
  const classes = useStyles(props);


  const {
    id,
    input: { onChange: finalFormOnChange, onBlur: finalFormOnBlur, value },
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });
  console.log("InputValue", value);

  const handleChangeType = useCallback(
    (event) => {
      let  newValue = event.target.value;
      const [rowId, val] = newValue.split('||')
      newValue = { ...value };
      if (!newValue[rowId]) {
        newValue[rowId] = {}
      }
      newValue[rowId]['dayType'] = val;
      console.log("newValue", newValue, rowId, val)
      finalFormOnChange(newValue);
      // finalFormOnBlur();
    },
    [value, finalFormOnChange]
  );
  const handleChangeTime = useCallback(
    (fieldValue, rowId, type) => {
      let newValue;
      newValue = { ...value };
      if (!newValue[rowId]) {
        newValue[rowId] = {}
      }
      newValue[rowId][type] = fieldValue;
      console.log("newValue", newValue, rowId, fieldValue)
      finalFormOnChange(newValue);
      // finalFormOnBlur();
    },
    [value, finalFormOnChange]
  );



  return (
    <FormControl component="fieldset"
                 margin={margin}
                 error={touched && !!error}
                 {...sanitizeRestProps(rest)}
    >
      <FormLabel component="legend" className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </FormLabel>
      <FormGroup row={row}>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell>День</TableCell>
                <TableCell align="left">Режим</TableCell>
                <TableCell align="left">Время</TableCell>
                <TableCell align="justify" size={'medium'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map(({ id: rowId, name: rowName }) => (
                <TableRow key={rowId}>
                  <TableCell component="th" scope="row">
                    {rowName}
                  </TableCell>
                  <TableCell align="left">
                    <DayTypeInputItem
                      key={`${rowId}_dayType`}
                      name={`${rowId}_dayType`}
                      id={`${rowId}`}
                      onChange={(event) => handleChangeType(event)}
                      value={(value && value[rowId] && value[rowId]['dayType']) || null}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.timeContainer}>
                      <div className={classes.timeInput}>
                    <DayTimeInputItem
                      key={`${rowId}_startTime`}
                      name={`${rowId}_startTime`}
                      id={`${rowId}`}
                      onChange={(value) => handleChangeTime(value, rowId,'startTime')}
                      value={(value && value[rowId] && value[rowId]['startTime']) || null}
                    />
                      </div>
                    <div className={classes.timeSep}>-</div>
                      <div className={classes.timeInput}>
                    <DayTimeInputItem
                      key={`${rowId}_endTime`}
                      name={`${rowId}_endTime`}
                      id={`${rowId}`}
                      onChange={(value) => handleChangeTime(value, rowId,'endTime')}
                      value={(value && value[rowId] && value[rowId]['endTime']) || null}
                    />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


      </FormGroup>
      <FormHelperText>

      </FormHelperText>
    </FormControl>
  );
};

WorkScheduleInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  optionValue: PropTypes.string,
  row: PropTypes.bool,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool,

  defaultValue: PropTypes.object,
};

WorkScheduleInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
  fullWidth: true,
  values: [],
  row: true,
};

export default WorkScheduleInput;
