import * as React from 'react';
import {FC, useState} from 'react';
import {
  Edit,
  EditButton,
  EditProps,
} from 'react-admin';
import {ProfileForm} from 'src/resources/profile/ProfileForm'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Пользователь {record.name}</span> : null;

const ProfileEdit: FC<EditProps> = props => {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<Title/>}>
     <ProfileForm isEdit/>
    </Edit>
  );
};
export default ProfileEdit;