import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  TabbedForm,
  ReferenceInput,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';

import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'



const InfoTab = props => {

  const form = useForm();
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (
    <FormTab
      label="Информация"
      path={'benefit'}
      {...props}>
      <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth/>
      <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth/>

    </FormTab>
  )
}

export const TagCategoryForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
