import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  useCreate,
  useCreateSuggestionContext,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, useRefresh, useDataProvider, ReferenceInput, AutocompleteInput, FormDataConsumer,
} from 'react-admin';
import {TagForm} from 'src/resources/tag/TagForm'
import ModalForm from 'src/components/ModalForm'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {useForm} from 'react-final-form'
import {slug} from 'src/utils/formatters'

interface TitleProps {
  record?: any;
}

const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {

    form.change('tagSlug', slug(e.currentTarget.value));
  }
  return (<>
      <ReferenceInput  label="Категория" source="tagCategoryId" reference="tag-category"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       allowEmpty={false}
                       validate={required()}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={(record) => `${record?.name}`} />
      </ReferenceInput>
      <TextInput source={'tagName'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth/>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.tagCategoryId === 5 && (
          <TextInput source={'tagPlaceholderName'} label={'Название места на сайте'} helperText={''} variant={'outlined'}  fullWidth/>
        )}
      </FormDataConsumer>
      <TextInput source={'tagSlug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth/>
      <TextInput source={'tagDescription'} multiline={true} label={'Краткое описание'} variant={'outlined'} fullWidth={true}/>
      <MediaInput source={'tagImage'} label={'Картинка'} />
    </>
  )
}
export const TagCreateModal = (props) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate('tag');

  const handleSubmit = (data) => {

    create(
      {
        payload: {
          data: {
            tagCategoryId: data.tagCategoryId,
            name: data.tagName,
            placeholderName: data.tagPlaceholderName,
            slug: data.tagSlug,
            description: data.tagDescription,
            image: data.tagImage,
          },
        },
      },
      {
        onSuccess: ({ data }) => {

          onCreate(data);
        },
      }
    );
  };

  return <ModalForm
    {...props}
    title={'Добавить Тег'}
    isShown
    onClose={() => {
      console.log("CloseCancel", onCancel);
      onCancel();
    }}
    save={handleSubmit}
    fullWidth={true}
  >
  <InfoTab/>
  </ModalForm>
}

export default TagCreateModal;