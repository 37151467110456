import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { CreateButton } from 'react-admin'

const RelatedEmptyList = ({ basePath, title = 'List empty',  record,
                            data,
                            ids,  description, buttonText, createButton,  to, hasCreate = true }: any) => (
  <Box textAlign="center" m={4}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">
      {description}
    </Typography>
    {(hasCreate && !createButton) && <CreateButton label={buttonText}   basePath={basePath} to={to} />}
    {(hasCreate && createButton) &&  React.cloneElement(createButton,{
      record,
      data,
      ids,
    }) }
  </Box>
)
export default RelatedEmptyList
