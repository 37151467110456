import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  useCreate,
  useCreateSuggestionContext,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, useRefresh, useDataProvider, ReferenceInput, AutocompleteInput, FormDataConsumer,
} from 'react-admin';
import {TagForm} from 'src/resources/tag/TagForm'
import ModalForm from 'src/components/ModalForm'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {useForm} from 'react-final-form'
import {slug} from 'src/utils/formatters'

interface TitleProps {
  record?: any;
}

const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {
    form.change('citySlug', slug(e.currentTarget.value));
  }
  return (<>
      <BooleanInput source="cityPublished" label={'Опубликовано'} variant={'outlined'} defaultValue={true}/>
      <TextInput source={'cityName'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth/>
      <TextInput source={'citySlug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth/>

    </>
  )
}
export const CityCreateModal = (props) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate('tag');

  const handleSubmit = (data) => {

    create(
      {
        payload: {
          data: {
            published: data.cityPublished,
            name: data.cityName,
            slug: data.citySlug,
          },
        },
      },
      {
        onSuccess: ({ data }) => {

          onCreate(data);
        },
      }
    );
  };

  return <ModalForm
    {...props}
    title={'Добавить Город'}
    isShown
    onClose={() => {
      onCancel();
    }}
    save={handleSubmit}
    fullWidth={true}
  >
  <InfoTab/>
  </ModalForm>
}

export default CityCreateModal;