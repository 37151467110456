import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm} from 'react-final-form'

import {
  BooleanInput,
  TabbedForm,
  Datagrid,
  ReferenceInput,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  AutocompleteInput,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  Filter, ReferenceManyField, TextField, DeleteButton, SelectField, FunctionField, ReferenceField,
} from 'react-admin';
import {formatPhone, formatUserName, slug} from 'src/utils/formatters'
import {
  AverageBillTypeList, HotelNewsTypeList, ReservationDurationList,
  ReservationStatus,
  ReservationStatusList,
  ReservationType,
  ReservationTypeList
} from 'src/types'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {HotelNewsForm} from 'src/resources/property/hotel/news/HotelNewsForm'
import AssetField from 'src/components/fields/AssetField'
import {RestaurantNewsList} from 'src/resources/restaurant/news/RestaurantNewsList'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import FormToolbarWithCancel from 'src/components/FormToolbarWithCancel'

export const UserReservationList = (props) => {
  const {record} = props;
  return (

    <ReferenceManyField reference="reservation" target="userId" record={{...record, id: record?.userId}}  addLabel={false} sort={{field: 'id', order: 'ASC'}}
                        perPage={50}>
      <RelatedList record={record}
                   hasCustomCreate={false}
                   emptyTitle={'Нет Резервов'}>
        <IdShowButtonField source={'id'} label={'ID'}/>
        <SelectField source={'status'} label={'Статус'} choices={ReservationStatusList}/>
        <SelectField source={'reservationType'} label={'Тип'} choices={ReservationTypeList}/>
        <TextField source={'name'} label={'Имя'}/>
        <DateField source="reservationTime" label={'Время резерва'} showTime={true} />
        <ReferenceField label="Ресторан" source="restaurantId" reference="restaurant">
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField label="Менеджер" source="managerId" reference="admin">
          <TextField source="name"/>
        </ReferenceField>
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>)
}

export const UserReservationListTab = props => {
  return (<FormTab
    label="Резервы клиента"
    path={'news'}
    {...props}>
    <UserReservationList/>
  </FormTab>)
}
const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (
    <FormTab
      label="Информация"
      path={'benefit'}
      {...props}>
      <ReferenceInput  label="Ресторан" source="restaurantId" reference="restaurant"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       allowEmpty={false}
                       validate={required()}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText={(record) => `${record?.name}`} />
      </ReferenceInput>
      <SelectInput source="status" label={'Статус'}  variant={'outlined'} choices={ReservationStatusList}  defaultValue={ReservationStatus.Approved} resettable={true}/>

      <TextInput source="name" label={'Имя'}  variant={'outlined'} validate={required()}/>

      <TextInput source="phone" label={'Телефон'}  variant={'outlined'} validate={required()}/>
      <DateTimeInput
        label="Время резерва"
        source="reservationTime"
        variant={'outlined'}
        fullWidth={true}
        validate={required()}
      />
      <SelectInput source="reservationDuration" label={'Длительность'}  variant={'outlined'} choices={ReservationDurationList}  defaultValue={60} resettable={true}/>
      <SelectInput source="type" label={'Что бронируем'}  variant={'outlined'} choices={ReservationTypeList}  defaultValue={ReservationType.Table} resettable={true}/>

      <TextInput source="numOfPersons" label={'Кол-во гостей'} defaultValue={2}  variant={'outlined'} resettable={true}/>

      <TextInput source="comment" label={'Комментарий клиента'}  variant={'outlined'}  multiline={true} fullWidth/>
      <TextInput source="commentManager" label={'Комментарий менеджера'}  variant={'outlined'}  multiline={true} fullWidth/>
      <TextInput source="user.reservationComment" label={'Комментарий менеджера о клиенте'}  variant={'outlined'}  multiline={true} fullWidth/>
      <ReferenceInput  label="Менеджер" source="managerId" reference="admin"  variant={'outlined'}
                       filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                       sort={{field: 'name', order: 'ASC'}}
                       filter={{role: 'restaurantManager'}}
                       allowEmpty={false}
                       fullWidth={true} perPage={100} resettable={true}>
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
    </FormTab>
  )
}

export const ReservationForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'} toolbar={<FormToolbarWithCancel/>}>
      <InfoTab/>
      {props.isEdit && <UserReservationListTab/>}
    </TabbedForm>
  );
};
