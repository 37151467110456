import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm, useFormState} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  AutocompleteInput, Filter,
} from 'react-admin';
import {MediaInput} from 'src/components/inputs/MediaInput'
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import {MenuItemList} from 'src/resources/restaurant/menu-items/MenuItemList'
import {RestaurantNewsList} from 'src/resources/restaurant/news/RestaurantNewsList'
import TagCreateModal from 'src/resources/tag/TagCreateModal'
import {AverageBillType, AverageBillTypeList, PushTypes} from 'src/types'
import WorkScheduleInput from 'src/components/inputs/WorkScheduleInput'
import CityCreateModal from 'src/resources/city/CityCreateModal'

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'}
               fullWidth={true}/>
  </FormTab>)
}
const CuisineTab = props => {
  return (<FormTab
    label="Кухня/Галерея"
    path={'contacts'}
    {...props}>
    <RichTextInput source="cuisine" label={'О кухне'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'gallery'} label={'Галерея'} isMulti/>
    <MediaInput source={'cuisineGallery'} label={'Галерея кухня'} helperText={'Галерея для вывода в разделе кухня'}
                isMulti/>
  </FormTab>)
}

const ChefAndResTab = props => {
  return (<FormTab
    label="Ресторан/Шеф"
    path={'seo'}
    {...props}>
    <RichTextInput source={'about'} multiline={true} label={'О ресторане'} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'aboutImage'} label={'Картинка блока о ресторане'}/>

    <TextInput source="chefName" label={'Шеф Имя'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'chefImage'} label={'Фото шефа'}/>
    <RichTextInput source="chefAbout" label={'О шефе'} multiline={true} variant={'outlined'} fullWidth={true}/>


    <TextInput source="chefSommelierName" label={'Шеф Сомелье Имя'} multiline={true} variant={'outlined'}
               fullWidth={true}/>
    <MediaInput source={'chefSommelierImage'} label={'Фото шефа Сомелье'}/>
    <RichTextInput source="chefSommelierAbout" label={'О шефе Сомелье'} multiline={true} variant={'outlined'}
                   fullWidth={true}/>

    <TextInput source="chefConfectionerName" label={'Шеф Кондитенер Имя'} multiline={true} variant={'outlined'}
               fullWidth={true}/>
    <MediaInput source={'chefConfectionerImage'} label={'Фото шефа Кондитенера'}/>
    <RichTextInput source="chefConfectionerAbout" label={'О шефе Кондитенере'} multiline={true} variant={'outlined'}
                   fullWidth={true}/>

    <TextInput source="chefBarName" label={'Шеф Бармен Имя'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'chefBarImage'} label={'Фото шефа бармена'}/>
    <RichTextInput source="chefBarAbout" label={'О шефе бармене'} multiline={true} variant={'outlined'}
                   fullWidth={true}/>
  </FormTab>)
}


const FeaturesTab = props => {
  const form = useForm();
  const {values} = useFormState();
  const {record} = props;
  useEffect(() => {

    if (!record) {
      return;
    }

    if (record.featuresTags && !values.featuresTagsIds) {
      form.change('featuresTagsIds', record?.featuresTags?.map(i => i.id));
      form.change('featuresTags', []);
    }



  }, [record])
  return (<FormTab
    label="Особенности"
    path={'features'}
    {...props}>
    <ReferenceArrayInput
      label={'Уточняющие теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="featuresTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'}
                              createItemLabel={'Создать тег'}/>

    </ReferenceArrayInput>
    <RichTextInput source="features" label={'Особенности'}
                   helperText={'Расскажите о фишках и особенностях ресторана коротко'} multiline={true}
                   variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'featuresGallery'} label={'Галерея'} isMulti/>
  </FormTab>)
}

const MenuItemListTab = props => {
  return (<FormTab
    label="Меню"
    path={'seo'}
    {...props}>
    <MenuItemList/>
  </FormTab>)
}

const RestaurantNewsListTab = props => {
  return (<FormTab
    label="Новости"
    path={'news'}
    {...props}>
    <RestaurantNewsList/>
  </FormTab>)
}

const InfoTab = props => {
  const form = useForm();
  const {values} = useFormState();
  const {record} = props;
  useEffect(() => {

    if (!record) {
      return;
    }

    if (record.tags && !values.tagsIds) {
      form.change('tagsIds', record?.tags?.map(i => i.id));
      form.change('tags', []);
    }
    if (record.featureTags && !values.featureTagsIds) {
      form.change('featureTagsIds', record?.featureTags?.map(i => i.id));
      form.change('featureTags', []);
    }
    if (record.spPlacementTags && !values.spPlacementTagsIds) {
      form.change('spPlacementTagsIds', record?.spPlacementTags?.map(i => i.id));
      form.change('spPlacementTags', []);
    }
    if (record.relatedRestaurants && !values.relatedRestaurantsIds) {
      form.change('relatedRestaurantsIds', record?.relatedRestaurants?.map(i => i.id));
      form.change('relatedRestaurants', []);
    }


  }, [record])
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} defaultValue={true} variant={'outlined'}/>

    <TextInput source={'name'} label={'Название'} onChange={handleNameChange} variant={'outlined'} validate={required()}
               fullWidth={true}/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'description'} multiline={true} label={'Краткое описание'} variant={'outlined'} fullWidth={true}
               validate={required()}/>
    <MediaInput source={'image'} label={'Картинка'} helperText={'Используется для вывода в карточках'}
                validate={required()}/>
    <MediaInput source={'imageLarge'} label={'Картинка Большая'}
                helperText={'Фоновая картинка на странице ресторана (1 экран)'}/>
    <MediaInput source={'logo'} label={'Логотип'}
                helperText={'Используется для вывода на странице ресторана, отзыва, брони'}/>

    <ReferenceArrayInput
      label={'Главные теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'}
                              createItemLabel={'Создать тег'}/>
    </ReferenceArrayInput>
    <SelectInput
      source="averageBillType"
      label={'Ср. чек тип'}
      variant={'outlined'} fullWidth={true}
      choices={AverageBillTypeList}
    />
    <NumberInput source={'averageBill'} label={'Ср. чек сумма'} variant={'outlined'} fullWidth={true}/>
    <ReferenceInput
      allowEmpty={false}
      label="Город" source="cityId" reference="city" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<CityCreateModal/>} createLabel={'Создать город'} createItemLabel={'Создать город'} />
    </ReferenceInput>
    <TextInput source="address" label={'Адрес'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="addressDescription" label={'Описание адреса (Как добряться)'} multiline={true}
               variant={'outlined'} fullWidth={true}/>
    <TextInput source="location" label={'Координаты'} helperText={'Через запуятую, например 55.616351,36.431444'}
               parse={value => {
                 const parts = value.split(',');
                 if (parts.length < 2 || !parts[0] || !parts[1]) {
                   return value;
                 }
                 return {lat: parts[0].trim(), lng: parts[1].trim()}
               }}
               format={value => {
                 if (value?.lat && value.lng) {
                   return `${value.lat},${value.lng}`
                 }
                 return value;
               }}
               multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="site" label={'Сайт'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="instagramLink" label={'Instagram'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="facebookLink" label={'Facebook'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="vkontakteLink" label={'VK'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="mainFeature" label={'Главный тег (для карточек лента)'} multiline={true} variant={'outlined'}
               fullWidth={true}/>
    <TextInput source="feature" label={'Фишка (Для карточек)'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="cuisineType" label={'Кухня (для карточек)'} multiline={true} variant={'outlined'}
               fullWidth={true}/>
    <WorkScheduleInput source="workingDays" label={'Режим работы'}
                       values={[
                         {id: 'monday', name: 'Понедельник'},
                         {id: 'thursday', name: 'Вторник'},
                         {id: 'wednesday', name: 'Среда'},
                         {id: 'tuesday', name: 'Четверг'},
                         {id: 'friday', name: 'Пятница'},
                         {id: 'saturday', name: 'Суббота'},
                         {id: 'sunday', name: 'Воскресенье'},]}
    />
    <ReferenceArrayInput
      label={'Похожие рестораны'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="relatedRestaurantsIds" reference="related-restaurants"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''}/>
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label={'Теги спецразмещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''}
                              create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'}/>
    </ReferenceArrayInput>
  </FormTab>)
}

export const RestaurantForm = props => {
  console.log("EditProps", props);
  return (
    <TabbedForm {...props} initialValues={{
      workingDays: {
        "monday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        },
        "thursday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        },
        "wednesday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        },
        "tuesday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        },
        "friday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        },
        "saturday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        },
        "sunday": {
          "startTime": "10:00",
          "endTime": "22:00",
          "dayType": "working"
        }
      }
    }} redirect={'list'}>
      <InfoTab/>
      {<FeaturesTab/>}
      <ChefAndResTab/>
      <CuisineTab/>
      {props.isEdit && <MenuItemListTab/>}
      {props.isEdit && <RestaurantNewsListTab/>}
      <SeoTab/>
    </TabbedForm>
  );
};
