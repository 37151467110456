import * as React from 'react';
import {FC} from 'react';
import {
  Create,
  EditProps,
} from 'react-admin';
import {PropertyForm} from './PropertyForm'
import {PropertyType} from 'src/types'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новая Недвижимость</span>

const PropertyCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <PropertyForm redirect={'show'} initialValues={{type: PropertyType.Property}}/>
    </Create>
  );
};

export default PropertyCreate;