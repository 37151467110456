import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Pagination,
  FunctionField,
  ReferenceField,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  useDataProvider,
  DeleteButton,
  useRefresh,
  TabbedForm,
  TextField,
  ReferenceManyField,
  TextInput, SelectField, Filter,
} from 'react-admin';

import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import OrderButtons from 'src/components/list/OrderButtons'
import {RestaurantNewsForm} from 'src/resources/restaurant/news/RestaurantNewsForm'
import AssetField from 'src/components/fields/AssetField'
import {MenuItemForm} from 'src/resources/restaurant/menu-items/MenuItemForm'
import {ArticleTypeList, RestaurantNewsType, RestaurantNewsTypeList} from 'src/types'


interface Props {
  record?: any;
}

export const RestaurantNewsList = (props) => {
  const {record} = props;
  const data = (record.benefits || []).sort((a, b) => (a.sort) - (b.sort));
  return (

    <ReferenceManyField reference="restaurant-news" target="restaurantId" addLabel={false} sort={{field: 'id', order: 'ASC'}}
                        perPage={50}>
      <RelatedList record={record}
                   emptyTitle={'Нет Новостей'}
                   actions={<ModalCreateButton modal={<RestaurantNewsForm mainRecord={record}/>}/>}
                   createButton={<ModalCreateButton modal={<RestaurantNewsForm mainRecord={record}/>}/>}>
        <AssetField source={'image'} label={'Картинка'}/>
        <TextField source={'name'} label={'Название'}/>
        <SelectInput source="type" label={'Тип'} choices={RestaurantNewsTypeList} variant={'outlined'}  resettable={true}/>

        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <ModalEditButton modal={<RestaurantNewsForm mainRecord={record}/>}/>
        <DeleteButton label={''} mutationMode="pessimistic" redirect={false}/>
      </RelatedList>
    </ReferenceManyField>)
}