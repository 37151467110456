import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  TextField,
  ListProps,
  Datagrid,
  FunctionField,
  BooleanInput, DateField, SimpleForm, SelectInput, SelectField, Tab, ReferenceField, ReferenceInput, AutocompleteInput
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import LinkField from 'src/components/fields/LinkField'

import {formatPhone, formatUserFirstLastName} from 'src/utils/formatters'
import ProfileNameField from "src/components/fields/ProfileNameField";
import {PrepaymentType, PrepaymentTypeList, ProfileRoleList, ProfileStatusList, PushTypes} from "src/types";


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'role': 'Роль',
      'name': 'Название',
      'firstName': 'Имя',
      'lastName': 'Имя',
      'status': 'Статус',
      'phone': 'Телефон',
      'email': 'Email',
      'autoApproveEnabled': 'Статус',
      'prepaymentType': 'Статус',
      'prepaymentMinPercent': 'Статус',
      'totalFeedbacks': 'Кол-во отзывов',
      'totalLikes': 'Кол-во лайков',
      'rating': 'Рейтинг',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      role: formatListValue(ProfileRoleList, item.role),
      status: formatListValue(ProfileStatusList, item.status),
      prepaymentMinPercent: formatListValue(PrepaymentTypeList, item.prepaymentType),
      autoApproveEnabled: item.autoApproveEnabled ? 'Авто' : '',
      phone: formatPhone(item.owner?.phone),
      email: item.owner?.email,
      name: formatUserFirstLastName(item),
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Profiles'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'}  variant={'outlined'}   resettable={true}/>

    <SelectInput source="role" choices={ProfileRoleList} label={'Роль'} variant={'outlined'}   resettable={true}/>
    <TextInput source="owner.phone||$contL" label={'Телефон'}  variant={'outlined'}  resettable={true}/>
    <TextInput source="owner.email||$contL" label={'Email'}  variant={'outlined'}  resettable={true}/>
    <TextInput source="firstName||$contL" label={'Название'}  variant={'outlined'}   resettable={true}/>
    <TextInput source="lastName||$contL" label={'Название'}  variant={'outlined'}   resettable={true}/>
    <SelectInput source="status" choices={ProfileStatusList} label={'Статус'} variant={'outlined'}   resettable={true}/>

  </Filter>
)

const ProfileList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Профили"
      empty={<EmptyList title={'Нет Профилей'} />}
    >
    <Datagrid >
      <IdShowButtonField source={'id'} label={'ID'}/>
      <SelectField source="role" choices={ProfileRoleList} label={'Роль'}/>
      <ProfileNameField source={'name'}/>
      <FunctionField source="owner.phone" label={'Телефон'} render={(item) => formatPhone(item.phone)}/>
      <TextField source="owner.email" label={'Email'}/>
      <SelectField source="status" choices={ProfileStatusList} label={'Статус'}/>
      <SelectField source="prepaymentType" choices={PrepaymentTypeList} label={'Статус'}/>
      <FunctionField source="autoApproveEnabled" label={'Авто апрув'} render={(item) => item.autoApproveEnabled ? 'Авто' : ''}/>
      <TextField source="rating" label={'Рейтинг'}/>
      <DateField source="createdAt" label={'Регистрация'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />

    </Datagrid>
    </List>
  );
}

export default ProfileList;
