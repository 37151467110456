import React from 'react'
import PropTypes from 'prop-types'
import {getTranslation} from 'src/utils/translation'

const PushNameField = ({ record = {},
                       }: any) => {
  return (<>
   <div><b>{getTranslation(record)?.title}</b></div>
    {getTranslation(record)?.body && <div>{getTranslation(record)?.body}</div>}
  </>)
}

PushNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}

PushNameField.defaultProps = {

};
export default PushNameField
