import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {useForm, useFormState} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import {MediaInput} from 'src/components/inputs/MediaInput'
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import TagCreateModal from 'src/resources/tag/TagCreateModal'
import {
  ArticleEntityListType,
  ArticleEntityListTypeList,
  ArticleGroupTypeList,
  ArticleType,
  ArticleTypeList
} from 'src/types'
import {formatListValue} from 'src/utils/exporter'

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}

const GalleryTab = props => {
  return (<FormTab
    label="Галерея"
    path={'contacts'}
    {...props}>
    <MediaInput source={'gallery'} label={'Галерея'} helperText={'Дополнительные фото на главном баннере страницы статьи'}  isMulti/>
  </FormTab>)
}

const InfoTab = props => {
  const form = useForm();
  const { values } = useFormState();
  const {record} = props;

  useEffect(() => {
    if (!record) {
      return;
    }

    if (record.tags && !values.tagsIds) {
      form.change('tagsIds', record?.tags?.map(i => i.id));
      form.change('tags', []);
    }

    if (record.spPlacementTags && !values.spPlacementTagsIds) {
      form.change('spPlacementTagsIds', record?.spPlacementTags?.map(i => i.id));
      form.change('spPlacementTags', []);
    }
    if (record.restaurants && !values.restaurantsIds) {
      form.change('restaurantsIds', record?.restaurants?.map(i => i.id));
      form.change('restaurants', []);
    }
    if (record.events && !values.eventsIds) {
      form.change('eventsIds', record?.events?.map(i => i.id));
      form.change('events', []);
    }
    if (record.entertainments && !values.entertainmentsIds) {
      form.change('entertainmentsIds', record?.entertainments?.map(i => i.id));
      form.change('entertainments', []);
    }



  }, [record])
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  const handleEntityTypeChange = (e) => {
    form.change('restaurantsIds', []);
    form.change('eventsIds', []);
    form.change('entertainmentsIds', []);
    form.change('propertiesIds', []);
  }
  const PropertyListTypeFieldMap = {
    [ArticleEntityListType.Property]: 'Недвижимость',
    [ArticleEntityListType.Hotel]: 'Отели',
    [ArticleEntityListType.Boat]: 'Лодки',
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>

    <ReferenceInput  label="Категория" source="articleCategoryId" reference="article-category"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     fullWidth={true} perPage={100} resettable={true} validate={required()} >
      <AutocompleteInput optionText={(record) => `${record?.group ? `${ formatListValue(record?.group, ArticleGroupTypeList)} -> ` : ``} ${record?.name}`}/>
    </ReferenceInput>

    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <SelectInput source={'type'} label={'Тип'} choices={ArticleTypeList} variant={'outlined'}  onChange={handleEntityTypeChange} validate={required()} fullWidth={true}/>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === ArticleType.EntityList  && (
        <SelectInput source={'entityListType'} label={'Вид списка'} choices={ArticleEntityListTypeList}  onChange={handleEntityTypeChange} helperText={`Какие виды вы хотите вывести на странице`} validate={required()}  variant={'outlined'} multiline fullWidth={true}/>
      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === ArticleType.EntityList && formData.entityListType === ArticleEntityListType.Restaurants  && (
        <ReferenceArrayInput
          label={'Рестораны'}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="restaurantsIds" reference="restaurant"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteArrayInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === ArticleType.EntityList && formData.entityListType === ArticleEntityListType.Events  && (
        <ReferenceArrayInput
          label={'События'}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="eventsIds" reference="event"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteArrayInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === ArticleType.EntityList && formData.entityListType === ArticleEntityListType.Entertainment  && (
        <ReferenceArrayInput
          label={'Экскурсии'}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="entertainmentsIds" reference="entertainment"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteArrayInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === ArticleType.EntityList && [ArticleEntityListType.Property, ArticleEntityListType.Hotel, ArticleEntityListType.Boat].includes(formData.entityListType)  && (
        <ReferenceArrayInput
          label={PropertyListTypeFieldMap[formData.entityListType]}
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
          allowEmpty={false} source="propertiesIds" reference="property"
          format={users => (users || []).map((user => user && user.id ? user.id : user))}
          variant={'outlined'} fullWidth={true}
          sort={{field: 'name', order: 'ASC'}}
        >
          <AutocompleteArrayInput source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>


    <DateTimeInput
      label="Время публикации"
      source="publishedAt"
      variant={'outlined'}
      fullWidth={true}
    />
    <ReferenceArrayInput
      label={'Теги'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
    <MediaInput source={'image'} label={'Картинка'} />
    <MediaInput source={'imageLarge'} label={'Картинка Большая'} />
    <TextInput source={'description'} multiline={true} label={'Краткое описание'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <RichTextInput source={'text'} multiline={true} label={'Текст'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'authorName'} multiline={true} label={'Автор'} variant={'outlined'}  fullWidth={true}/>
    <ReferenceArrayInput
      label={'Теги спецразмещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''} create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
  </FormTab>)
}

export const ArticleForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      <GalleryTab/>
      <SeoTab/>
    </TabbedForm>
  );
};
