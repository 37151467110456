import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  TabbedForm,
  ReferenceInput,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {ArticleGroupTypeList, ArticleTypeList} from 'src/types'
import {MediaInput} from 'src/components/inputs/MediaInput'

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}

const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (
    <FormTab
      label="Информация"
      path={'benefit'}
      {...props}>
      <BooleanInput source="published" label={'Опубликовано'}  defaultValue={true} variant={'outlined'}/>
      <SelectInput source={'group'} label={'Группа'} choices={ArticleGroupTypeList} variant={'outlined'} validate={required()} fullWidth={true}/>
      <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth/>
      <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth/>
      <TextInput source={'description'} multiline={true} label={'Краткое описание'} variant={'outlined'} fullWidth/>
      <MediaInput source={'image'} label={'Картинка'} helperText={'Превью в слайдерах'} />
      <MediaInput source={'imageLarge'} label={'Картинка Большая'} />
      <TextInput source={'key'} label={'Ключ'} variant={'outlined'} fullWidth helperText={'Используется для метки вывода на сайте в определенном месте'}/>

    </FormTab>
  )

}

export const ArticleCategoryForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      <SeoTab/>
    </TabbedForm>
  );
};
