import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'

import {useForm, useFormState} from 'react-final-form'

import {
  BooleanInput,
  Datagrid,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import {MediaInput} from 'src/components/inputs/MediaInput'
import {formatUserName, slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import TagCreateModal from 'src/resources/tag/TagCreateModal'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import TimeInput from 'src/components/inputs/TimeInput'
import WorkScheduleInput from 'src/components/inputs/WorkScheduleInput'
import CityCreateModal from 'src/resources/city/CityCreateModal'
import {EntityStatusList, EntityStatus} from 'src/types';

const SeoTab = props => {
  return (<FormTab
    label="SEO"
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}
const AboutGalleryTab = props => {
  return (<FormTab
    label="О экскурсии'/Галерея"
    path={'contacts'}
    {...props}>
    <RichTextInput source="about" label={'О экскурсии'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <MediaInput source={'gallery'} label={'Галерея'}  isMulti/>
   </FormTab>)
}

const InfoTab = props => {
  const form = useForm();
  const { values } = useFormState();
  const {record} = props;
  useEffect(() => {

    if(!record){
      return;
    }
    if (record.tags && !values.tagsIds) {
      form.change('tagsIds', record?.tags?.map(i => i.id));
      form.change('tags', []);
    }

    if (record.spPlacementTags && !values.spPlacementTagsIds) {
      form.change('spPlacementTagsIds', record?.spPlacementTags?.map(i => i.id));
      form.change('spPlacementTags', []);
    }


  }, [record])
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} defaultValue={true} variant={'outlined'}/>
    <SelectInput
        source="status"
        label={'Статус'}
        choices={EntityStatusList}
        variant={'outlined'} 
      />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.status === EntityStatus.Rejected && (
        <TextInput source={'moderatorComment'} label={'Комментарий модератора'}  variant={'outlined'} validate={required()} fullWidth={true}/>
      )}
    </FormDataConsumer>
    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth={true}/>
    <MediaInput source={'image'} label={'Картинка'} helperText={'Используется для вывода в карточках'}  />
    <MediaInput source={'imageLarge'} label={'Картинка Большая'} helperText={'Фоновая картинка на странице экскурсии (1 экран)'} />

    <ReferenceArrayInput
      label={'Главные теги'}
      filter={{tagCategoryId: 11}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="tagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceArrayInput>
    <BooleanInput source="withGuide" label={'С гидом'} defaultValue={true} variant={'outlined'}/>
    <NumberInput source={'minPrice'} label={'Минимальная цена'} variant={'outlined'} fullWidth={true}/>

    <ReferenceInput
      allowEmpty={false}
      label="Категория (главный тег)" source="entertainmentTypeTagId" reference="tag" variant={'outlined'}
      filter={{tagCategoryId: 1}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
    </ReferenceInput>
    <TimeInput
      label="Время Начало"
      source="startTime"
      variant={'outlined'}
      fullWidth={true}
    />
    <TimeInput
      label="Время Конец"
      source="endTime"
      variant={'outlined'}
      fullWidth={true}
    />
    <ReferenceInput
      allowEmpty={false}
      label="Город" source="cityId" reference="city" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      sort={{ field: 'name', order: 'ASC' }}
      fullWidth={true} perPage={100}>
      <AutocompleteInput source="id" optionText="name" create={<CityCreateModal/>} createLabel={'Создать город'} createItemLabel={'Создать город'} />
    </ReferenceInput>
    <TextInput source="address" label={'Адрес'} helperText={'Описание адрес или как добраться'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="location" label={'Координаты'} helperText={'Через запуятую, например 55.616351,36.431444'}
               parse={value => {
                 const parts = value.split(',');
                 if(parts.length < 2 || !parts[0] || !parts[1]){
                   return value;
                 }
                 return {lat: parts[0].trim(), lng: parts[1].trim()}
               }}
               format={value => {
                 if(value?.lat && value.lng){
                   return `${value.lat},${value.lng}`
                 }
                 return value;
               }}
               multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="feature" label={'Фишка (Для карточек)'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="site" label={'Сайт'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="instagramLink" label={'Instagram'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="facebookLink" label={'Facebook'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="vkontakteLink" label={'VK'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <BooleanInput source="hasWorkingDays" label={'Есть расписание'} defaultValue={true} variant={'outlined'}/>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.hasWorkingDays && (
        <WorkScheduleInput source="workingDays" label={'Режим работы'}
                           values={[
                             {id: 'monday', name: 'Понедельник'},
                             {id: 'thursday', name: 'Вторник'},
                             {id: 'wednesday', name: 'Среда'},
                             {id: 'tuesday', name: 'Четверг'},
                             {id: 'friday', name: 'Пятница'},
                             {id: 'saturday', name: 'Суббота'},
                             {id: 'sunday', name: 'Воскресенье'},]}
        />
        )}
    </FormDataConsumer>
    <BooleanInput source="kidsAllowed" label={'Можно с детьми'} defaultValue={true} variant={'outlined'}/>
    <BooleanInput source="petsAllowed" label={'Можно с животными'} defaultValue={true} variant={'outlined'}/>
    <TextInput source="importantInfo" label={'Важные детали'} multiline={true} variant={'outlined'} fullWidth={true} helperText={'Что взять с собой?Форма одежды и обуви.Уровень сложности.Правила отмены.Ограничения(если они есть)'}/>
    <ArrayInput source="priceIncludesDescription" label={'Что входить стоимость (Списком)'} variant={'outlined'}  >
      <SimpleFormIterator>
        <TextInput source="name" label={''} variant={'outlined'}  multiline={true} fullWidth={true}/>
       </SimpleFormIterator>
    </ArrayInput>

    <ReferenceInput  label="Пользователь" source="ownerId" reference="user"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'phone||$contL': searchText} : {})})}
                     sort={{field: 'firstName', order: 'ASC'} }
                     allowEmpty={false}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={formatUserName} />
    </ReferenceInput>
     <ReferenceArrayInput
      label={'Теги спецразмещения'}
      filter={{tagCategoryId: 5}}
      filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
      allowEmpty={false} source="spPlacementTagsIds" reference="tag"
      format={users => (users || []).map((user => user && user.id ? user.id : user))}
      variant={'outlined'} fullWidth={true}
      sort={{field: 'name', order: 'ASC'}}
    >
       <AutocompleteArrayInput source="id" optionText={i => i?.placeholderName || i?.name || ''} create={<TagCreateModal/>} createLabel={'Создать тег'} createItemLabel={'Создать тег'} />
     </ReferenceArrayInput>
  </FormTab>)
}

export const EntertainmentForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
      <AboutGalleryTab/>
      <SeoTab/>
    </TabbedForm>
  );
};
