import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";
export const AuthProvider = {
  login: ({ username, password }: any) => {
    const request = new Request(`${process.env.REACT_APP_API_URL || ''}/admin/api/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ accessToken }) => {
        localStorage.setItem('permissions', 'admin')
        Cookies.set('auth-token', accessToken)
      })
  },
  logout: () => {
    Cookies.remove('auth-token')
    return Promise.resolve()
  },
  checkAuth: (params: any) => {

    if (window.location.pathname === '/singup') {
      return Promise.resolve()
    }
    if (!Cookies.get('auth-token')) {

      return Promise.reject({ redirectTo: '/login' })
    }
    return Promise.resolve()
  },
  checkError: (error: any) => {
    const status = error.status
    if (status === 401 || status === 403) {
      Cookies.remove('auth-token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: () => {
    try {
      const {role} = jwt_decode(Cookies.get('auth-token')) as any;
      console.log("ROLE", role)
      return Promise.resolve(role)
    }catch (e){
      return Promise.reject();
    }
  },
}
