import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import {formatPhone} from 'src/utils/formatters'

const ProfileNameField = ({ source, sourceName, record }) => {
  const hasFirstLastName = record.lastName || record.lastName;
  const name = record.name
  const phone = record.phone
  return (<>
    {name && <Typography variant={'body2'}>{name}</Typography>}
    {hasFirstLastName && <Typography variant={'body2'}>{`${record.lastName || ''}${` ${record.firstName || ''}` || ''}`}</Typography>}
    </>)
}
ProfileNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  sourceName: PropTypes.string,
}
ProfileNameField.defaultProps = {
  sourceName: 'name'
};

export default ProfileNameField
