import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SimpleForm, TextField, ReferenceInput, required, AutocompleteInput, ReferenceField
} from 'react-admin';
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import {formatUserName} from 'src/utils/formatters'
import UserField from 'src/components/fields/UserField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'author': 'Авто',
      'article': 'Статья',
      'restaurant': 'Ресторан',
      'event': 'Событие',
      'createdAt': 'Создано',
    },
    data:  posts.map(item => ({
      ...item,
      author: item.phone,
      article: item.article?.name,
      event: item.event?.name,
      restaurant: item.restaurant?.name,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Like'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <ReferenceInput  label="Автор" source="userId" reference="user"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'phone||$contL': searchText} : {})})}
                     sort={{field: 'phone', order: 'ASC'}}
                     allowEmpty={false}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={formatUserName} />
    </ReferenceInput>
    <ReferenceInput  label="Ресторан" source="restaurantId" reference="restaurant"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <ReferenceInput  label="Статья" source="articleId" reference="article"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>
    <ReferenceInput  label="Событие" source="eventId" reference="event"  variant={'outlined'}
                     filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})}
                     sort={{field: 'name', order: 'ASC'}}
                     allowEmpty={false}
                     validate={required()}
                     fullWidth={true} perPage={100} resettable={true}>
      <AutocompleteInput optionText={(record) => `${record?.name}`} />
    </ReferenceInput>

  </Filter>
)

const LikeList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Лайки"
      empty={<EmptyList title={'Нет лайков'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <TextField source={'id'} label={'ID'}/>
      <ReferenceField label="Автор" source="userId" reference="user">
        <UserField source="name"/>
      </ReferenceField>
      <ReferenceField label="Ресторан" source="restaurantId" reference="restaurant">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Статья" source="articleId" reference="article">
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField label="Событие" source="eventId" reference="event">
        <TextField source="name"/>
      </ReferenceField>
      <TextField source={'mark'} label={'Оценка'} />

      <DateField source="createdAt" label={'Создано'}showTime={true} />
    </Datagrid>
    </List>
  );
}

export default LikeList;
