import slugify from 'slugify'
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const slug = (val) => val ? slugify(val)?.replace(/[`~!@#$%^&*()|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')?.toLowerCase()?.trim() || '' : '';
export const formatPhone = (phone) => {
  if(!phone){
    return;
  }
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, 'RU');
    return phoneUtil.format(number, PNF.INTERNATIONAL)
  }catch (e){
    console.error(e);
    return phone;
  }
}
const pluralizeNative = require('numeralize-ru').pluralize
export const pluralize = (number, word1, word2, word3) => {
  return pluralizeNative(number, word1, word2, word3)
}
export const isString = thing => Object.prototype.toString.call(thing) === '[object String]';

export const formatDataWithTranslation = ({fields, data, langCode}) => {
  const translations = data.translations || [];
  let currentTranslationIndex = translations.findIndex(i => i.languageCode === langCode);
  if(currentTranslationIndex === -1) {
    currentTranslationIndex = 0;
    translations.push({languageCode: langCode});
  }
  const newData = {...data};
  for (const i of fields) {
    translations[currentTranslationIndex][i] = newData[i] || '';

    delete newData[i];
  }
  return {...newData, translations};
}
export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
export const formatPrice = (price: number) => {
  if(!price){
    return;
  }
  return `${formatNumber(Math.ceil(price ))} ₽`
}

export const formatBonuses = (bonuses: number, withStr = false) => {

  const number = bonuses ? Math.ceil(bonuses / 100) : 0;
  return `${formatNumber(number)}${withStr ? ` Б` : ''}`
}

export const convertJson = (json) => {
  if(!json){
    return;
  }
  try{

    return JSON.parse(json);
  }catch (e){
    return null;
  }
}
export const formatUserName = (record: any) => {
  return  `${record?.phone} ${ (record?.firstName || record?.lastName)  ? `(${formatUserFirstLastName(record)})` : ''}`
}
export const formatUserFirstLastName = (record: any) => {
  return  `${ (record?.firstName || record?.lastName) ? ` ${record?.lastName || ''}${record?.firstName ? ` ${record?.firstName }` : ''}` : ''}`
}