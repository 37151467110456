import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Show,
  TabbedShowLayout,
  SimpleForm,
  DateField,
  Tab,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, SelectField, FunctionField,
} from 'react-admin';

import {formatPhone} from 'src/utils/formatters'

import LinkField from 'src/components/fields/LinkField'
import {PrepaymentTypeList, ProfileRoleList, ProfileStatusList} from "src/types";
import ProfileNameField from "src/components/fields/ProfileNameField";

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Пользователь #{record.id} {record.defaultProfile?.name}</span>

const ProfileShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <SelectField source="role" choices={ProfileRoleList} label={'Роль'}/>
        <ProfileNameField source={'name'}/>
        <FunctionField source="owner.phone" label={'Телефон'} render={(item) => formatPhone(item.phone)}/>
        <TextField source="owner.email" label={'Email'}/>
        <SelectField source="status" choices={ProfileStatusList} label={'Статус'}/>
        <SelectField source="prepaymentType" choices={PrepaymentTypeList} label={'Статус'}/>
        <FunctionField source="autoApproveEnabled" label={'Авто апрув'} render={(item) => item.autoApproveEnabled ? 'Авто' : ''}/>
        <TextField source="rating" label={'Рейтинг'}/>
        <DateField source="createdAt" label={'Регистрация'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
        <LinkField value={'Брони'} label={'Заказы'} link={'/booking??displayedFilters={"ownerProfileId":true}&filter={"ownerProfileId": $id}'}/>

      </Tab>

    </TabbedShowLayout>
  </Show>)
};

export default ProfileShow;