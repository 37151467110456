import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  TextField,
  Datagrid,
  BooleanInput, DateField
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'title': 'Название',
      'body': 'Содержимое',
      'published': 'Опубликовано',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      title: getTranslation(item)?.title,
      body: getTranslation(item)?.body,
      published: item.published ? 'Да' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Page'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <TextInput source="body||$contL" label={'Содержимое'}  variant={'outlined'}  resettable={true}/>
    <BooleanInput source={'published'} label={'Опубликовано'}/>
    <DateInput source={'date'} label={'Дата'}/>

  </Filter>
)

const PageList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Текстовые страницы"
      empty={<EmptyList title={'Нет тестовых страниц'} description={'Вы можете добавить текстовую страницу'} buttonText={'Добавить текстовую страницу'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <TextField source="name" label={'Название'}/>
      <TextField source="slug" label={'URI'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default PageList;
