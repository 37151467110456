import HotelList from 'src/resources/property/hotel/HotelList'
import HotelEdit from 'src/resources/property/hotel/HotelEdit'
import HotelCreate from 'src/resources/property/hotel/HotelCreate'
import RestaurantIcon from '@material-ui/icons/List';
import HotelIcon from '@material-ui/icons/Hotel';
export default {
  create: HotelCreate,
  edit: HotelEdit,
  list: HotelList,
  icon: HotelIcon,
};