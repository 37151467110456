import * as React from 'react';
import {FC} from 'react';
import {Box, Chip, useMediaQuery, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  CreateButton,
  ExportButton,
  Filter,
  SelectField,
  List,
  FilterProps,
  InputProps,
  ListBase,
  ListProps,
  Datagrid,
  TextField,
  TopToolbar,
  useListContext,
  useTranslate, DateField, SelectInput,
} from 'react-admin';
import {AdminRoles} from 'src/types'


const useQuickFilterStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const QuickFilter: FC<InputProps> = ({label}) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.root} label={translate(label)}/>;
};

const ListActions: FC<any> = ({isSmall}) => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const AdministratorList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ListActions/>}
      title="Администраторы"
    >
    <Datagrid rowClick={'edit'}>
      <TextField source="name" label={'Имя'}/>
      <TextField source="email" label={'Email'}/>
      <SelectField
        source="role"
        label={'Роль'}
        choices={AdminRoles}
      />
      <DateField source={'createdAt'} label={'Создан'}/>
    </Datagrid>
    </List>
  );
}

export default AdministratorList;
