import ChatIcon from '@material-ui/icons/Chat';
import FeedbackList from './FeedbackList'
import FeedbackEdit from './FeedbackEdit'
import FeedbackCreate from './FeedbackCreate'

export default {
  create: FeedbackCreate,
  edit: FeedbackEdit,
  list: FeedbackList,
  icon: ChatIcon,
};